import React from 'react';
import classNames from "classnames";
import NumberFormat from "react-number-format";
import arrow from "../../../../assets/images/arrow_blue.svg";
import "./index.scss";

function ControlPanelResult({
  data: {
    nodesNumber,
    gatewaysNumber,
    expectedSpeed,
    // usersCount,
  },
  isDownloading,
  backButtonClick,
  handleDownload,
  handleScheduleCall,
  // handleSend,
  handlePreview, buttonsEnabled,
  // isSendEnabled,
  isScheduleEnabled,
}) {
  return (
    <div className="control-panel-wrapper">
      <div className="title-block title-button" onClick={backButtonClick}><img src={arrow} alt="arrow" />Change Polygons</div>
      <div className="stats-block">
        <div className="stats-item">
          <span className="title">Nodes:</span>
          <span className="value">{nodesNumber ? <NumberFormat displayType={'text'} thousandSeparator="," value={nodesNumber} /> : 0}</span>
        </div>
        <div className="stats-item">
          <span className="title">Gateways:</span>
          <span className="value">{gatewaysNumber ? <NumberFormat displayType={'text'} thousandSeparator="," value={gatewaysNumber} /> : 0}</span>
        </div>
        <div className="stats-item">
          <span className="title">Network throughput:</span>
          <span className="value">{expectedSpeed ? <NumberFormat displayType={'text'} thousandSeparator="," value={expectedSpeed} /> : 0}Mbps</span>
        </div>
        <div className="stats-item">
          <span className="title">Users:</span>
          <span className="value">{nodesNumber ? <NumberFormat displayType={'text'} thousandSeparator="," value={nodesNumber * 40} /> : 0}</span>
        </div>
      </div>
      <div className="actions-block actions-block-result">
        {/*<div
          className={classNames("control-button button-call", !isScheduleEnabled && "control-button-disabled")}
          onClick={isScheduleEnabled ? handleScheduleCall : () => {}}
        />*/}
        <div
          className={classNames("control-button button-download", (!buttonsEnabled || isDownloading) && "control-button-disabled")}
          onClick={buttonsEnabled ? handleDownload : () => {}}
        />
        <div
          className={classNames("control-button button-send", !isScheduleEnabled && "control-button-disabled")}
          onClick={isScheduleEnabled ? handleScheduleCall : () => {}}
        />
        <div className={classNames("control-button button-preview")} onClick={handlePreview} />
      </div>
    </div>
  );
}
export default ControlPanelResult;
