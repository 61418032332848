function getCountryShortName(results) {
  let csm = "";
  results.forEach((resC) => {
    resC.address_components.forEach((csmItem) => {
      if (csmItem.types.indexOf("country") !== -1) {
        csm = csmItem.short_name;
      }
    });
  });
  return csm;
}
export default getCountryShortName;
