import { request } from '../../util/requestWrapper';
import {
  SET_PROPOSAL_PDFGEN_DATA,
  SET_PROPOSAL_PDFSEND_DATA,
  SET_PROPOSAL_CHECK_DATA,
  SET_PROPOSALS_DATA,
  SET_PROPOSAL_DATA,
  SET_PROPOSAL_DATA_DEV,
  SET_PROPOSAL_SCHEDULECALL_DATA,
  SET_PROPOSALS_FETCHING,
} from '../ActionTypes';

export const getProposalsList = () => {
  return async (dispatch) => {
    dispatch({ type: SET_PROPOSALS_FETCHING, payload: true });
    try {
      const data = await request({ method: 'GET', url: `me/proposals` });
      if (data) {
        dispatch({ type: SET_PROPOSALS_DATA, payload: data });
        dispatch({ type: SET_PROPOSAL_DATA, payload: data[0]});
        dispatch({ type: SET_PROPOSALS_FETCHING, payload: false });
      }
    } catch (error) {
      dispatch({ type: SET_PROPOSALS_FETCHING, payload: false });
      const message =
        error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].message
          ? error.response.data[0].message
          : error.message;
      console.log(message);
    }
  };
};

export const getProposalNoAuth = ({ uid, id }) => {
  return async (dispatch) => {
    try {
      const data = await request({ method: 'GET', url: `dev/users/${uid}/proposals/${id}` });
      if (data.id) {
        dispatch({ type: SET_PROPOSAL_DATA_DEV, payload: data });
      }
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].message
          ? error.response.data[0].message
          : error.message;
      console.log(message);
    }
  };
};

export const checkProposal = ({ id }) => {
  return async (dispatch) => {
    try {
      const data = await request({ method: 'GET', url: `/me/proposals/${id}/check-answers` });
      dispatch({ type: SET_PROPOSAL_CHECK_DATA, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].message
          ? error.response.data[0].message
          : error.message;
      console.log(message);
    }
  };
};

export const downloadProposalPdf = ({ id }) => {
  return async (dispatch) => {
    try {
      return await request({ method: 'GET', url: `/me/proposals/${id}/download` });
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].message
          ? error.response.data[0].message
          : error.message;
      console.log(message);
    }
  };
};

export const sendProposalPdf = ({ id }) => {
  return async (dispatch) => {
    dispatch({ type: SET_PROPOSAL_PDFSEND_DATA, payload: { status: false, isFetching: true } });
    try {
      const data =  await request({ method: 'POST', url: `/me/proposals/${id}/send-mail?send=true` });
      if (data) {
        dispatch({ type: SET_PROPOSAL_PDFSEND_DATA, payload: { status: data.data, isFetching: false } });
      }
      return data;
    } catch (error) {
      dispatch({ type: SET_PROPOSAL_PDFSEND_DATA, payload: { status: false, isFetching: false } });
      const message =
        error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].message
          ? error.response.data[0].message
          : error.message;
      console.log(message);
    }
  };
};

export const scheduleProposalCall = ({ id }) => {
  return async (dispatch) => {
    dispatch({ type: SET_PROPOSAL_SCHEDULECALL_DATA, payload: { status: false, isFetching: true } });
    try {
      const data =  await request({ method: 'GET', url: `/me/proposals/${id}/schedule-call?send=true` });
      if (data) {
        dispatch({ type: SET_PROPOSAL_SCHEDULECALL_DATA, payload: { status: data.data, isFetching: false } });
      }
      return data;
    } catch (error) {
      dispatch({ type: SET_PROPOSAL_SCHEDULECALL_DATA, payload: { status: false, isFetching: false } });
      const message =
        error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].message
          ? error.response.data[0].message
          : error.message;
      throw Error(message);
    }
  };
};

export const generateProposalPdf = ({ id }) => {
  return async (dispatch) => {
    dispatch({ type: SET_PROPOSAL_PDFGEN_DATA, payload: { status: false, isFetching: true } });
    try {
      const data = await request({ method: 'PATCH', url: `/me/proposals/${id}/generate-pdf` });
      if (data) {
        dispatch({ type: SET_PROPOSAL_PDFGEN_DATA, payload: { status: data.data, isFetching: false } });
      }
    } catch (error) {
      dispatch({ type: SET_PROPOSAL_PDFGEN_DATA, payload: { status: false, isFetching: false } });
      const message =
        error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].message
          ? error.response.data[0].message
          : error.message;
      console.log(message);
    }
  };
};

export const saveProposal = ({ id, body }) => {
  return async (dispatch) => {
    try {
      let data = {};
      if (id) {
        data = await request({ method: 'PATCH', url: `me/proposals/${id}`, body: body });
      } else {
        data = await request({ method: 'POST', url: `me/proposals`, body: body });
      }
      if (data.id) {
        dispatch({ type: SET_PROPOSAL_DATA, payload: data });
      }
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].message
          ? error.response.data[0].message
          : error.message;
      console.log(message);
    }
  };
};

export const saveProposalMapType = ({ id, body }) => {
  return async (dispatch) => {
    try {
      let data = {};
      if (id) {
        dispatch({ type: SET_PROPOSALS_FETCHING, payload: true });
        data = await request({ method: 'PATCH', url: `me/proposals/${id}/map-type`, body: body });
      } else {
        return;
      }
      if (data.id) {
        dispatch({ type: SET_PROPOSAL_DATA, payload: data });
        dispatch({ type: SET_PROPOSALS_FETCHING, payload: false });
      }
    } catch (error) {
      dispatch({ type: SET_PROPOSALS_FETCHING, payload: false });
      const message =
        error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].message
          ? error.response.data[0].message
          : error.message;
      console.log(message);
    }
  };
};
