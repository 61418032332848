const connectivityType = [{
  id: "Cellular",
  title: 'Cellular',
  mbps: 40,
},{
  id: "Ethernet/Fiber",
  title: 'Ethernet / Fiber',
  mbps: 350,
},{
  id: "I don't know",
  title: "I don't know",
  mbps: 40,
},{
  id: "None",
  title: 'None',
}];

const peopleCount = [
  {
    id: "0-100",
    title: "0-100",
    val: "100",
  },
  {
    id: "100-300",
    title: "100-300",
    val: "300",
  },
  {
    id: "300-600",
    title: "300-600",
    val: "600",
  },
  {
    id: "600-900",
    title: "600-900",
    val: "900",
  },
  {
    id: "1000+",
    title: "1000+",
    val: "1000",
  },
];

const usedFor = [{
  id: "E-learning",
  title: 'E-learning',
  bandwidth: 7,
},{
  id: "Streaming",
  title: 'Streaming',
  bandwidth: 7,
},{
  id: "Email",
  title: 'Email',
  bandwidth: 1,
},{
  id: "Social Media",
  title: 'Social Media',
  bandwidth: 3,
},{
  id: "General Web Use",
  title: 'General Web Use',
  bandwidth: 3,
}];

const powerAvailableList = [{
  id: "no",
  title: 'No power available',
},{
  id: "partial",
  title: 'Outlet power or PoE available at some install locations',
},{
  id: "yes",
  title: 'Outlet power or PoE available at all install locations',
}];

export {
  connectivityType,
  peopleCount,
  usedFor,
  powerAvailableList,
};
