import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Arrow from "../../../assets/images/arrow.svg";

const Step1 = ({ inVals, handleNext, dispatchData, isLogined }) => {
  const setInitVals = () => {
    return {
      name: inVals.name || '',
      company: inVals.company || '',
    };
  };
  const validator = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Required';
    } else if (
      values.name.length < 2
    ) {
      errors.name = 'The personal name must be at least 2 characters long';
    }
    return errors;
  };

  const submitter = (values, { setSubmitting }) => {
    setTimeout(() => {
      dispatchData(values);
      handleNext();
      setSubmitting(false);
    }, 400);
  };

  return (
    <div className="stepper-form-wrapper">
      <p className="stepper-header">General questions
        <span className="curr-step">1<span className="total-step">/7</span></span>
      </p>
      {/*<p className="stepper-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa dui tristique in fames.</p>*/}
      <Formik
        enableReinitialize={true}
        initialValues={setInitVals()}
        validate={validator}
        onSubmit={submitter}
      >
        {({ isSubmitting }) => (
          <Form className="custom-form" autoComplete="off">
            <div className="input-wrapper">
              <div className="input-label">Who Is this network being designed for?</div>
              <Field disabled={isLogined} placeholder="Full Name" type="name" name="name" className="custom-form-input" />
              <ErrorMessage name="name" className="error-message" component="div" />
            </div>
            <div className="input-wrapper">
              <Field placeholder="Company" type="company" name="company" className="custom-form-input" />
              <ErrorMessage name="company" component="div" />
            </div>
            <div className="stepper-buttons-wrapper">
              <button type="submit" disabled={isSubmitting} className="custom-form-button stepper-next-button">
                Next
                <img src={Arrow} alt="next" />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Step1;
