const apiHost = "https://api.design-tool.meshplusplus.com/api";
const googleAPIKey = "AIzaSyCGnoASmLh2aGqkv0hs-dpYaLReMZ_moLk";
const googleLibraries = ['geometry'];
const isIframe = true;
export {
  apiHost,
  googleAPIKey,
  googleLibraries,
  isIframe,
};

