import React from "react";
import PageFooter from "./Components/PageFooter";
import PageHeader from "./Components/PageHeader";
import "./index.scss";

export default function AppDashboard({ current, total }) {
  return (
    <div className="pdf-page-wrapper" id="p4">
      <div className="page-wrapper page2-wrapper">
        <div className="background-div" />
        <PageHeader isLightPage={true} />
        <div className="preview-content">
          <div className="title no-padding blue-title">
            <h2>S618</h2>
          </div>
          <div className="content">
            <div className="app-dash-6">
              <div className="bordered">Solar-powered 2x2 WiFi 6 Router</div>
              <div>Modular for any last-mile application</div>
            </div>
          </div>
          <div className="content app-dash-60-lists">
            <div>
            <ul className="blue-markers">
              <li><p>Range up to 350m line-of sight<sup>1</sup></p></li>
              <li><p>Supports 25-500 users<sup>2</sup></p></li>
              <li><p>Battery life up to 7 days</p></li>
              <li><p>IP67 waterproof</p></li>
              <li><p>10-minute installation</p></li>
            </ul>
            </div>
            <div>
              <p>Option</p>
              <ul>
                <li><p>GPS</p></li>
                <li><p>2GB RAM upgrade</p></li>
                <li><p>4x4 WiFi 6E radio</p></li>
                <li><p>LoRaWAN modem</p></li>
              </ul>
            </div>
            <div>
              <p>&nbsp;</p>
              <ul>
                <li><p>4x4 WiFi 6E radio</p></li>
                <li><p>4G LTE / 5G cellular modem</p></li>
                <li><p>802.3 af/at Power over Ethernet</p></li>
                <li><p>1.2kWh battery (7 days)</p></li>
              </ul>
            </div>
          </div>
          <div className="content app-dash-60-bottom">
            <div className="tables">
              <div className="table-title blue-title">Hardware</div>
              <div className="table">
                <div className="t-row ogg">
                  <div className="t-col">CPU</div>
                  <div className="t-col">1.8GHz quad-core ARM A53</div>
                </div>
                <div className="t-row even">
                  <div className="t-col">Memory</div>
                  <div className="t-col">1GB (up to 2GB)</div>
                </div>
                <div className="t-row ogg">
                  <div className="t-col">Boot Type</div>
                  <div className="t-col">32MB NOR flash, 128GB NAND flash</div>
                </div>
                <div className="t-row even">
                  <div className="t-col">Internal Expansion</div>
                  <div className="t-col">microSD, dual SIM, 2x M.2</div>
                </div>
                <div className="t-row ogg">
                  <div className="t-col">Wired Connectivity</div>
                  <div className="t-col">2x USB 2.0, RJ45 GbE LAN/WAN</div>
                </div>
              </div>
              <div className="table-title blue-title">Wireless</div>
              <div className="table">
                <div className="t-row ogg">
                  <div className="t-col">2.4GHz</div>
                  <div className="t-col">2x2 802.11b/g/n/ax 27dBm/chain (574Mbps)</div>
                </div>
                <div className="t-row even">
                  <div className="t-col">6GHz</div>
                  <div className="t-col"><span className="blue-color">4x4 802.11ax 23dBm/chain (5Gbps)<sup>+</sup></span></div>
                </div>
                <div className="t-row ogg">
                  <div className="t-col">5.8GHz</div>
                  <div className="t-col">2x2 802.11a/n/ac/ax 25dBm/chain (1201Mbps)</div>
                </div>
                <div className="t-row even">
                  <div className="t-col">WiFi Driver</div>
                  <div className="t-col">microSD, dual SIM, 2x M.2</div>
                </div>
                <div className="t-row ogg">
                  <div className="t-col">Bluetooth</div>
                  <div className="t-col">Bluetooth 5</div>
                </div>
                <div className="t-row even">
                  <div className="t-col">Cellular</div>
                  <div className="t-col"><span className="blue-color">CAT4-CAT20 4G LTE or sub-6GHz 5g DUAL-SIM<sup>+</sup></span></div>
                </div>
                <div className="t-row ogg">
                  <div className="t-col">Antennas</div>
                  <div className="t-col">4x 6GHz N-type female</div>
                </div>
              </div>
              <div className="table-title blue-title">Power</div>
              <div className="table">
                <div className="t-row ogg">
                  <div className="t-col">Power Delivery</div>
                  <div className="t-col">60W USB-C PD</div>
                </div>
                <div className="t-row even">
                  <div className="t-col">Power over Ethernet</div>
                  <div className="t-col"><span className="blue-color">13W 802.3af/at<sup>+</sup></span></div>
                </div>
                <div className="t-row ogg">
                  <div className="t-col">Solar</div>
                  <div className="t-col">18Vmpp 4A</div>
                </div>
                <div className="t-row even">
                  <div className="t-col">Solar Panel</div>
                  <div className="t-col">75W (stock), 40-100W</div>
                </div>
                <div className="t-row ogg">
                  <div className="t-col">Adapter</div>
                  <div className="t-col">12V 5A</div>
                </div>
                <div className="t-row even">
                  <div className="t-col">Bateries</div>
                  <div className="t-col">18650 lithium-ion, 894Wh (stock) - 1.2kWh</div>
                </div>
              </div>
              <div className="table-title blue-title">Physical</div>
              <div className="table">
                <div className="t-row ogg">
                  <div className="t-col">Dimensions</div>
                  <div className="t-col">110mm diameter * 640mm length + solar panel (external)</div>
                </div>
                <div className="t-row even">
                  <div className="t-col">Weight</div>
                  <div className="t-col">7kg</div>
                </div>
                <div className="t-row ogg">
                  <div className="t-col">Ingress Protection</div>
                  <div className="t-col">IP68</div>
                </div>
                <div className="t-row even">
                  <div className="t-col">Temperature</div>
                  <div className="t-col">-20C to 70C (storage), -10C to 30C (operating, ambient)</div>
                </div>
              </div>
            </div>
            <div className="app-dash-img-6" />
          </div>
        </div>
        <PageFooter current={current} total={total} />
      </div>
    </div>
  );
}
