import React  from 'react';
import Check from "../../../../assets/images/check.svg";
import "./styles.scss";
import classes from "classnames";
import { cloneDeep } from "lodash";

const TagsComponent = ({ tags = [], selected = [], handleSelect }) => {

  const itemClick = (itemId) => {
    const newSelected = cloneDeep(selected);
    if (selected.indexOf(itemId) !== -1) {
      handleSelect(selected.filter(item => item !== itemId));
    } else {
      newSelected.push(itemId);
      handleSelect(newSelected);
    }
  };

  const item = (data) => (
    <div key={data.id} className={classes("tag-item", selected.indexOf(data.id) !== -1 && "active")} onClick={() => itemClick(data.id)}>
      {data.title}
      {selected.indexOf(data.id) !== -1 && <img src={Check} style={{ marginLeft: "10px" }} alt="checked"/> }
    </div>
  );

  return (
    <div className="tags-list-5-step">
      {tags.map(data => item(data))}
    </div>
  );
};

export default TagsComponent;
