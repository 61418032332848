
export const SET_USER_DATA = 'SET_USER_DATA';
export const START_SESSION = 'START_SESSION';
export const CLEAR_SESSION = 'CLEAR_SESSION';


export const SET_PROPOSAL_SCHEDULECALL_DATA = 'SET_PROPOSAL_SCHEDULECALL_DATA';
export const SET_PROPOSAL_PDFSEND_DATA = 'SET_PROPOSAL_PDFSEND_DATA';
export const SET_PROPOSAL_PDFGEN_DATA = 'SET_PROPOSAL_PDFGEN_DATA';
export const SET_PROPOSAL_DATA_DEV = 'SET_PROPOSAL_DATA_DEV';
export const SET_PROPOSAL_DATA = 'SET_PROPOSAL_DATA';
export const SET_PROPOSALS_FETCHING = 'SET_PROPOSALS_FETCHING';
export const CLEAR_PROPOSAL_DATA = 'CLEAR_PROPOSAL_DATA';
export const SET_PROPOSALS_DATA = 'SET_PROPOSALS_DATA';
export const SET_PROPOSAL_CHECK_DATA = 'SET_PROPOSAL_CHECK_DATA';

export const SET_PREVIEW_DATA = 'SET_PREVIEW_DATA';
export const SET_PREVIEW_MAP_IMAGE = 'SET_PREVIEW_MAP_IMAGE';
export const CLEAR_PREVIEW_DATA = 'CLEAR_PREVIEW_DATA';

