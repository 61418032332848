import axiosSerivce from 'axios';
// import { request } from '../../util/requestWrapper'
import { CLEAR_SESSION, SET_USER_DATA, START_SESSION } from '../ActionTypes';
import { apiHost } from '../../util/config';

/*export const setUserData = () => {
  return async (dispatch) => {
    const data = await request({ method: 'GET', url: '/users' })
    if (data) {
      if (data.account) data.account.url = `${window.location.protocol}//${data.account.slug}.${host}`
      dispatch({ type: SET_USER_DATA, payload: data })
    }
  }
}*/

const axios = axiosSerivce.create({
  baseURL: apiHost,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const userSignIn = ({ email, name, phone, company }) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post('auth', { email, name, phone, company });
      if (data.accessToken) {
        localStorage.setItem('userId', data.id);
        localStorage.setItem('accessToken', data.accessToken);
        localStorage.setItem('refreshToken', data.refreshToken);
        dispatch({ type: START_SESSION });
        dispatch({ type: SET_USER_DATA, payload: data });
      }
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].message
          ? error.response.data[0].message
          : error.message;
      console.log(message);
      // notification.error({ message })
    }
  };
};
export const userLogout = () => {
  return async (dispatch) => {
    try {
      localStorage.removeItem('userId');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      dispatch({ type: CLEAR_SESSION, payload: false });
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].message
          ? error.response.data[0].message
          : error.message;
      console.log(message);
    }
  };
};

export const getUser = () => {
  return async (dispatch) => {
    try {
      const id = localStorage.getItem('userId');
      if (!id) return false;
      const { data } = await axios.get(`dev/users/${id}`, {});
      if (data.id) {
        dispatch({ type: START_SESSION });
        dispatch({ type: SET_USER_DATA, payload: data });
      }
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].message
          ? error.response.data[0].message
          : error.message;
      console.log(message);
      // notification.error({ message })
    }
  };
};
