import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useHistory} from "react-router-dom";
import { saveProposal, checkProposal } from "../../redux/actions";
import {GoogleApiWrapper} from "google-maps-react";
import AwesomeSlider from 'react-awesome-slider';
import CoreStyles from 'react-awesome-slider/src/core/styles.scss';
import AnimationStyles from 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss';
import {
  Step1, Step2,
  Step3, Step4,
  Step5, Step6,
  Step7,
} from "./Steps";
import "../pages.scss";
import "./stepper.scss";
import {confirm} from "../../Components/confirm";

function MainFormComponent() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const auth = useSelector(({auth}) => auth);
  const proposal = useSelector(({proposal}) => proposal.item);
  const isFetching = useSelector(({proposal}) => proposal.isFetching);
  const checked = useSelector(({proposal}) => proposal.checked || false);
  const [stepData, setStepData] = useState(auth.userData || {} );
  const [proposalData, setProposalData] = useState(proposal?.item || {} );
  const [selected, setSelected] = useState(location?.state?.step || 0);

  useEffect(() => {
    if (location?.state?.step) {
      setSelected(location?.state?.step);
    }
  }, [location]);

  useEffect(() => {
    if(auth.userData && !stepData.name) {
      setStepData({...stepData, ...auth.userData});
    }
  }, [auth.userData, stepData.name, stepData]);

  useEffect(() => {
    if(proposal && Object.keys(proposalData).length === 0) {
      setProposalData(proposal);
    }
  }, [proposal, proposalData, setProposalData]);

  useEffect(() => {
    if(checked && checked.length === 0) {
      window.location.href = "/map";
    } else if (checked && checked.length !== 0) {
      history.push( { pathname: '/form', state: { step: 2 } });
      confirm({
        confirmActionText: "Ok", // optional
        confirmAction: () => {}, // optional
        text: `Please, check all fields before continue`, // optional
      });
    }
  }, [checked, history]);

  const handleNext = () => {
    setSelected((old) => old < 6  ? old += 1 : 0);
  };

  const handleNextCheck = () => {
    dispatch(checkProposal({ id: proposal.id }));
  };

  const handlePrev = () => {
    setSelected((old) => old > 0  ? old -= 1 : 0);
  };

  const dispatchData = (newVals) => {
    setStepData({...stepData, ...newVals});
  };

  const dispatchDataProposal = (newVals) => {
    setProposalData({...proposalData, ...newVals});
  };

  const saveProposalAction = (values) => {
    return dispatch(saveProposal({ id: proposal ? proposal.id : false, body: values }));
  };

  return (
    !isFetching && (
      <AwesomeSlider
        animation="foldOutAnimation"
        className="main-form-slider"
        selected={selected}
        organicArrows={false}
        bullets={false}
        infinite={false}
        cssModule={[CoreStyles, AnimationStyles]}
      >
        <div className="content-wrapper">
          <Step1 handleNext={handleNext} inVals={stepData} dispatchData={dispatchData} isLogined={auth.isLogined} />
        </div>
        <div className="content-wrapper">
          <Step2 handleNext={handleNext} handlePrev={handlePrev} inVals={stepData} dispatchData={dispatchData} isLogined={auth.isLogined} />
        </div>
        <div className="content-wrapper">
          <Step3 handleNext={handleNext} handlePrev={handlePrev} inVals={proposalData} saveProposal={saveProposalAction} dispatchData={dispatchDataProposal} />
        </div>
        <div className="content-wrapper">
          <Step4 handleNext={handleNext} handlePrev={handlePrev} inVals={proposalData} saveProposal={saveProposalAction} dispatchData={dispatchDataProposal} />
        </div>
        <div className="content-wrapper">
          <Step5 handleNext={handleNext} handlePrev={handlePrev} inVals={proposalData} saveProposal={saveProposalAction} dispatchData={dispatchDataProposal} />
        </div>
        <div className="content-wrapper">
          <Step6 handleNext={handleNext} handlePrev={handlePrev} inVals={proposalData} saveProposal={saveProposalAction} dispatchData={dispatchDataProposal} />
        </div>
        <div className="content-wrapper">
          {checked && checked.length > 0 && (
            <div>
              Something wrong with:
              <ul>
                {checked.map(item => <li key={item}>{item}</li>)}
              </ul>
            </div>
          )}
          <Step7 handleNext={handleNextCheck} handlePrev={handlePrev} inVals={proposalData} saveProposal={saveProposalAction} dispatchData={dispatchDataProposal} />
        </div>
      </AwesomeSlider>
    )
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCGnoASmLh2aGqkv0hs-dpYaLReMZ_moLk",
  libraries: ["places", "visualization", "geometry"],
})(MainFormComponent);
