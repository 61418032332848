import axios from 'axios';
import { apiHost } from './config';

const getToken = () => localStorage.getItem('accessToken');

const updateToken = async () => {
  let result = null;
  const client = axios.create({ baseURL: apiHost });
  const refreshToken = localStorage.getItem('refreshToken');
  const id = localStorage.getItem('userId');
  const headers = { 'Content-Type': 'application/json' };
  try {
    const response = await client.post(`/auth-token-refresh`, { refreshToken, id }, { headers });
    if (response.status === 200) {
      localStorage.setItem('refreshToken', response.data.refreshToken);
      localStorage.setItem('accessToken', response.data.accessToken);
      result = response.data.accessToken;
    }
  } catch (error) {
    console.log(error);
  }
  return result;
};

const request = async ({ body, headers, method, params, url, ignoreError, returnError }) => {
  const client = axios.create({ baseURL: apiHost });
  let token = getToken();
  const allHeaders = {
    ...headers,
    Authorization: `${token}`,
    'Content-Type': 'application/json',
  };
  const query = params ? `?${URLSearchParams(params).toString()}` : '';
  const methodName = method.toLowerCase();
  const args = ['get', 'delete'].includes(methodName)
    ? [url + query, { headers: allHeaders }]
    : [url, body, { headers: allHeaders }];
  let response;
  let err;
  try {
    response = await client[methodName](...args);
    return response.data;
  } catch (error) {
    err = error;
    if (error.response && error.response.status === 401) {
      token = await updateToken();
      if (token) {
        args[['get', 'delete'].includes(methodName) ? 1 : 2].headers.Authorization = `${token}`;
        response = await client[methodName](...args);
        return response.data;
      }
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('userId');
      document.location.href = '/';
      return null;
    }

    if (!ignoreError || error.response.status !== ignoreError) {
      let description = false;
      let message = error.toString();
      if (error.response) {
        message =
          error.response.status === 400
            ? `Invalid data`
            : `An error occurred on the server with status code ${error.response.status}`;
        if (error.response.data.message) {
          description = error.response.data.message;
        }
      }
      const e = new Error();
      e.message = description || message;
      throw e;
    }
  }
  return (returnError && err) || null;
};

const getAuthHeaders = () => {
  return { Authorization: `${getToken()}` };
};

export { request, getAuthHeaders };
