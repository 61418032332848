import React from "react";
import PageFooter from "./Components/PageFooter";
import PageHeader from "./Components/PageHeader";
import "./index.scss";

export default function Technology({ current, total, proposal }) {
  return (
    <div className="pdf-page-wrapper" id="p4">
      <div className="page-wrapper page2-wrapper">
        <div className="background-div" />
        <PageHeader isLightPage={true} />
        <div className="preview-content">
          <div className="title">
            <h2>Technology and Typical Design Process</h2>
          </div>
          <div className="title second-title blue-title">
            <h2>Technology</h2>
          </div>
          <div className="content">
            <p>Operating on a backbone of battery- and solar-powered mesh nodes, Mesh++’s ultra-efficient mesh networking protocol allows almost all wiring to be eliminated from last-mile connectivity. The cost and installation difficulty of a cable-free network is dramatically lower than its wired analogue. Devoid of the primary challenges of last-mile infrastructure, Mesh++ routing allows broadband internet access to reach locations where it otherwise never could. Mesh++ self-powered mesh nodes feature modular antennas, a 3-day battery reserve, and optional solid-state storage up to 1TB. All devices are housed in a waterproof 2mm aluminum outer shell to survive anything nature can throw at them. By utilizing multiple gateways (cellular, ethernet, fiber), a redundant mesh network can approach 100% uptime without a single point of failure. All Mesh++ devices include a license to the Mesh++ dashboard, which displays powerful, intuitive visualizations on the web or a mobile device. A networking background is no longer required to understand how to optimize and maintain a network. Color-coded stresses and suggestions help overcome the social barriers of network maintenance in remote areas.</p>
          </div>
          <div className="title second-title blue-title">
            <h2>Typical Mapping (City Coverage)</h2>
          </div>
          <div className="content">
            <p>For equal coverage over an efficient mesh, nodes must be placed relatively equidistant from one another with line-of-sight to other nearby mesh nodes. Higher-density coverage may be required in high-traffic areas. Prior to planning individual installation locations, it is necessary to estimate coverage areas with a focus towards high-traffic areas. As the limiting factor, node coverage area should be considered to cover approximately 40 concurrent links, or up to 200 people with 20% access penetration at any time. An example coverage map is shown to the left. After a map is created, a mounting location can be found nearby. Access point locations can be modified or supplemented with modular antennas after installation for optimal service.</p>
          </div>
        </div>
        <PageFooter current={current} total={total} />
      </div>
    </div>
  );
}
