import React, {useEffect, useState} from 'react';
import { Formik, Form } from 'formik';
import Arrow from "../../../assets/images/arrow.svg";
import TagsComponent from "./TagsComponent/TagsComponent";
import { usedFor } from "../../constants";

let timer = false;

const Step5 = ({ inVals, handleNext, handlePrev, saveProposal, dispatchData }) => {
  const [fData, setFData] = useState({networkAim: inVals.networkAim || []});

  useEffect(() => {
    return (timer) ? clearTimeout(timer) : false;
  });

  const setInitVals = () => {
    return inVals || { networkAim: [] };
  };
  const validator = (values) => {
    const errors = {};
    const { networkAim } = fData;
    if (!networkAim || networkAim.length === 0) {
      errors.networkAim = "Need to select one item at least";
    }
    return errors;
  };

  const submitter = (values, { setSubmitting }) => {
    saveProposal({ ...values, ...fData })
      .then((res) => {
        dispatchData({ ...values, ...fData });
        handleNext();
        timer = setTimeout(() => setSubmitting(false), 1000);
      })
      .catch((err) => {
        setSubmitting(false);
      });
  };

  const handleChangeVal = (name, val) => {
    setFData({ ...fData, [name]: val });
  };
  const { networkAim } = fData;

  return (
    <div className="stepper-form-wrapper">
      <p className="stepper-header">Network Use
        <span className="curr-step">5<span className="total-step">/7</span></span>
      </p>
      <p className="stepper-text">Indicating what the network will be used for helps estimate the
        bandwidth needed per concurrent user. Streaming video requires more
        bandwidth than checking social media. The higher the bandwidth needs the more
        gateways required in your network.</p>
      <Formik
        initialValues={setInitVals()}
        validate={validator}
        onSubmit={submitter}
      >
        {({
            errors,
            isSubmitting
        }) => (
          <Form className="custom-form" autoComplete="off">
            <div className="input-wrapper">
              <div className="input-label">Select all that apply.</div>
              <TagsComponent
                tags={usedFor}
                selected={networkAim || []}
                handleSelect={(val) => {
                  handleChangeVal("networkAim", val);
                }}
              />
              {(!networkAim || networkAim.length === 0) && <div className="error-message">{errors.networkAim}</div>}
            </div>
            <div className="stepper-buttons-wrapper">
              <button type="button" disabled={isSubmitting} onClick={handlePrev} className="custom-form-button stepper-prev-button">
                <img src={Arrow} alt="prev" />
                Prev
              </button>
              <button type="submit" disabled={isSubmitting} className="custom-form-button stepper-next-button">
                Next
                <img src={Arrow} alt="next" />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Step5;
