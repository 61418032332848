import React from "react";
import PageFooter from "./Components/PageFooter";
import PageHeader from "./Components/PageHeader";
import "./index.scss";

export default function AppDashboard({ current, total }) {
  return (
    <div className="pdf-page-wrapper" id="p4">
      <div className="page-wrapper page2-wrapper">
        <div className="background-div" />
        <PageHeader isLightPage={true} />
        <div className="preview-content">
          <div className="title no-padding blue-title">
            <h2>What’s Inside the Box</h2>
          </div>
          <div className="content">
            <div className="app-dash-img-4" />
          </div>
          <div className="title app-dash-5 no-padding">
            <h2>Installation</h2>
          </div>
          <div className="content app-dash-5-bottom">
            <div className="app-dash-img-5" />
            <div>
              <ol>
                <li><p>Thread adjustable hose clamps though eyelets in Main Mount.</p></li>
                <li><p>Affix hose clamps securely to sturdy pole. Has to support up to 17 lbs.</p></li>
                <li><p>Place S618 Node Assembly <strong>Component Tube</strong> into cradle of Main
                  Mount in proper position, imprint
                  recessed area to line up with mount
                  on bottom, logos on End Seal Plates
                  horizontal, right reading. Make sure
                  enclosure is tight and can’t be moved
                  within the mount.</p></li>
                <li><p>Tighten metal mount clamp to S618
                  Node with turn knob on bottom of
                  Main Mount</p></li>
                <li><p>Attach Solar Panel Arm to top of Main
                  Mount. Plug in solar power cable to
                  connector on End Seal Plate.</p></li>
                <li><p>Thread spring steel ribbon through
                  angle adjustment gear, pulling and
                  turning angle adjustment knob on
                  either side of Main Mount. <span className="blue-color">Please
                  research the optimal angle for
                    sunlight based on your latitude.</span></p>
                </li>
                <li><p>(Optional) Plug in usb-a, usb-c,
                  ethernet cable or memory stick into
                  USB Splitter Housing Top as desired.</p>
                </li>
                <li><p>Snap into USB Splitter Housing
                  Bottom and clip into bottom horizontal
                  slots on both sides of Main Mount.</p></li>
              </ol>
            </div>
          </div>
          <div className="content">

          </div>
        </div>
        <PageFooter current={current} total={total} />
      </div>
    </div>
  );
}
