import React from "react";
import NumberFormat from "react-number-format";

function DisplaySquare({ proposal, valueAcres, hideUnits = false }) {
  return (
    proposal.addressShortName && proposal.addressShortName === "US" ? (
      <>
        <NumberFormat displayType={'text'} thousandSeparator="," value={valueAcres} /> {!hideUnits && "Acres"}
      </>
    ) : (
      <>
        <NumberFormat displayType={'text'} thousandSeparator="," value={(valueAcres * 4046.86).toFixed(0)} /> {!hideUnits && (<>m<sup>2</sup></>)}
      </>
    )
  );
}
export default DisplaySquare;
