import React, {useEffect} from "react";
import { useParams } from "react-router-dom";
import Page1 from "./PdfPages/Page1";
import MapArea from "./PdfPages/MapArea";
// import BatteryNodes from "./PdfPages/BatteryNodes";
import AppDashboard from "./PdfPages/AppDashboardNew";
import AppDashboard2 from "./PdfPages/AppDashboardNew2";
import AppDashboard3 from "./PdfPages/AppDashboardNew3";
// import BatteryNodesInstallation from "./PdfPages/BatteryNodesInstallation";
import SolarNodes from "./PdfPages/SolarNodes";
import SolarNodesInstallation from "./PdfPages/SolarNodesInstallation";
import InputData from "./PdfPages/InputData";

import {useDispatch, useSelector} from "react-redux";
import {getProposalNoAuth} from "../../redux/actions";
// import AuthDescr from "./PdfPages/AuthDescr";
import CostDescr_NoPrices from "./PdfPages/CostDescr_NoPrices";
// import CostDescr from "./PdfPages/CostDescr";
// import CostDescrNew from "./PdfPages/CostDescrNew";
import "./index.scss";
import Technology from "./PdfPages/Technology";
import ComingSoon from "./PdfPages/ComingSoon";
import Backhaul from "./PdfPages/Backhaul";
import S618 from "./PdfPages/S618";
import MeshPlusPlus from "./PdfPages/MeshPlusPlus";

export default function PreviewPage() {
  const { uid, id, page } = useParams();
  const auth = useSelector(({auth}) => auth);
  const proposal = useSelector(({proposal}) => proposal.itemDev);
  const dispatch = useDispatch();
  useEffect(() => {
    if (id && uid) {
      dispatch(getProposalNoAuth({uid, id}));
    }
  }, [uid, id, dispatch]);
  const prevLink = () => {
    let href = "/";
    if (parseInt(page) > 1) {
      href = `/preview/${uid}/${id}/${parseInt(page) - 1}`;
    } else if (auth.isLogined) {
      href = `/map`;
    }
    return <a id="back-btn" href={href}>Back</a>;
  };

  const getPageList = () => {
    const pages = [
      Page1,
      MapArea,
      InputData,
      MeshPlusPlus,
    ];
    if (proposal.urbanLevel < 50 || proposal.powerType === "no" || proposal.powerType === 'partial') {
      pages.push(SolarNodes);
      pages.push(SolarNodesInstallation);
    }
    // if (proposal.powerType === 'yes' || proposal.powerType === 'partial') {
    //   pages.push(BatteryNodes);
    //   pages.push(BatteryNodesInstallation);
    // }
    pages.push(AppDashboard);
    pages.push(AppDashboard2);
    pages.push(AppDashboard3);
    pages.push(Technology);
    pages.push(Backhaul);
    pages.push(ComingSoon);
    pages.push(S618);
    // pages.push(CostDescrNew);
    pages.push(CostDescr_NoPrices);
    return pages;
  };

  return (
    <div className="preview-page-wrapper">
      <div className="prev-navy">
        {prevLink()}
      </div>
      {proposal && getPageList().map((Item, key) => <Item key={key + 1} current={key + 1} total={getPageList().length} proposal={proposal} auth={auth}/>)}
    </div>
  );
}
