import React from 'react';
import './styles.scss';
import { Slider } from "rsuite";

const SliderUsersComponent = ({ selected, type, onChange, value }) => {
  const handleStyle = {
    color: '#fff',
    fontSize: 12,
    width: 32,
    height: 22
  };

  return (
    <div className="slider-wrapper">
      <div className="slider-points">
        <span>1</span>
        <span>5M</span>
      </div>
      <div className="slider">
        <Slider
          type={type}
          min={1}
          max={100}
          value={parseInt(value)}
          className="custom-slider"
          handleStyle={handleStyle}
          tooltip={false}
          handleTitle={false}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default SliderUsersComponent;
