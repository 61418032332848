import { SET_PROPOSALS_FETCHING, SET_PROPOSAL_SCHEDULECALL_DATA, SET_PROPOSAL_PDFSEND_DATA, SET_PROPOSAL_PDFGEN_DATA, SET_PROPOSAL_DATA_DEV, SET_PROPOSAL_CHECK_DATA, CLEAR_PROPOSAL_DATA, SET_PROPOSAL_DATA, SET_PROPOSALS_DATA } from '../ActionTypes';

const INIT_STATE = {
  item: null,
  itemDev: null,
  checked: false,
  isFetching: false,
  pdfGenerated: { status: false, isFetching: false },
  pdfSended: { status: false, isFetching: false },
  callScheduled: { status: false, isFetching: false },
  items: [],
};

const state = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_PROPOSAL_DATA: {
      return {
        ...state,
      };
    }
    case SET_PROPOSALS_FETCHING: {
      return {
        ...state,
        isFetching: action.payload,
      };
    }
    case SET_PROPOSAL_DATA: {
      return {
        ...state,
        item: action.payload,
      };
    }
    case SET_PROPOSALS_DATA: {
      return {
        ...state,
        items: action.payload,
      };
    }
    case SET_PROPOSAL_DATA_DEV: {
      return {
        ...state,
        itemDev: action.payload,
      };
    }
    case SET_PROPOSAL_CHECK_DATA: {
      return {
        ...state,
        checked: action.payload,
      };
    }
    case SET_PROPOSAL_PDFGEN_DATA: {
      return {
        ...state,
        pdfGenerated: action.payload,
      };
    }
    case SET_PROPOSAL_PDFSEND_DATA: {
      return {
        ...state,
        pdfSended: action.payload,
      };
    }
    case SET_PROPOSAL_SCHEDULECALL_DATA: {
      return {
        ...state,
        callScheduled: action.payload,
      };
    }
    default:
      return state;
  }
};

export default state;
