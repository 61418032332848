import React from "react";
import PageFooter from "./Components/PageFooter";
import PageHeader from "./Components/PageHeader";
import "./index.scss";

export default function Backhaul({ current, total, proposal }) {
  return (
    <div className="pdf-page-wrapper" id="p4">
      <div className="page-wrapper page2-wrapper">
        <div className="background-div" />
        <PageHeader isLightPage={true} />
        <div className="preview-content">
          <div className="title">
            <h2>Backhaul Options</h2>
          </div>
          <div className="content">
            <p>Mesh++ partners with over 70 connectivity providers to help customers find the right mid-mile solution(s) for their mesh network. For large coverage networks multiple backhaul types and vendors can be utilized to create a robust and redundant network.</p>

            <p>Mesh++ connects to wire-lined service via an ethernet port on our devices.</p>

            <p>Cellular modems can be built into the device and support 2 SIM cards from the same or different providers.</p>

            <p>Customers can elect to source their own backhaul solution or purchase solutions through Mesh++.</p>

          </div>
        </div>
        <PageFooter current={current} total={total} />
      </div>
    </div>
  );
}
