import React, { useEffect } from "react";
import "./app.scss";
import "./components.scss";
import classes from "classnames";
import { useLocation } from "react-router-dom";
import Router from "./Router";
import Topbar from "./Topbar";
import {useSelector, useDispatch} from "react-redux";
import {getUser, getProposalsList} from "../../redux/actions";
import {isIframe} from "../../util/config";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const auth = useSelector(({auth}) => auth);

  useEffect(() => {
    if (auth.isLogined && !auth.id) {
      dispatch(getUser());
      dispatch(getProposalsList());
    }
  }, [auth.isLogined, auth.id, dispatch]);

  const isMainPage = location.pathname === "/";
  const isPreviewPage = /preview/i.test(location.pathname);
  return isPreviewPage ? <Router /> : (
    <div className={classes(isMainPage ? (isIframe ? "main flex-page" : "main") : "all-pages")}>
      <div className="background-image-div" />
      {!isIframe ? (
        <div className="header-wrapper">
          { <Topbar isMainPage={isMainPage}/> }
          {isMainPage && (
            <div className="title-block">
              <h1>WIFi Anywhere</h1>
              <p>No infrastructure? No problem!</p>
            </div>
          )}
        </div>
      ) : <div className="header-wrapper minheight-header" />}
      <Router />
    </div>
  );
}

export default App;
