import {Circle, Polygon, Polyline} from "@react-google-maps/api";
import { usedFor, connectivityType } from "../../constants";
import React from "react";

const getSquare = (poly) => {
  return window.google.maps.geometry.spherical.computeArea(poly.getPath());
};

const getLineLengthPx = (start, end) => {
  const xLength = start.x - end.x;
  const yLength = start.y - end.y;
  return Math.sqrt(xLength * xLength + yLength * yLength);
};

const drawPolyline = (coords) => (
  <Polyline
    path={coords}
    options={{
      strokeColor: "#2F89FC",
      strokeOpacity: 0.8,
      strokeWeight: 1,
    }}
  />
);

const drawPolygon = (coords) => (
  <Polygon
    path={coords}
    options={{
      strokeColor: "#2F89FC",
      strokeOpacity: 1,
      strokeWeight: 1,
      fillColor: "rgba(47, 137, 252, 0.25)",
    }}
  />
);

const placeRectangle = (coords) => (
  <Polygon
    path={coords}
    options={{
      strokeColor: "#2F89FC",
      strokeOpacity: 1,
      strokeWeight: 1,
      fillColor: "rgba(47, 137, 252, 0.25)",
    }}
  />
);

const getAcresPerNode = (proposal) => {
  let acresForNode = 0;
  if (proposal.urbanLevel <= 33) {
    acresForNode = 10;
  } else if (proposal.urbanLevel > 33 && proposal.urbanLevel <= 66) {
    acresForNode = 5;
  } else {
    acresForNode = 2;
  }
  return acresForNode;
};

/**
 * Convert coordinates to visual pixels and get line length between them
 * @param projection
 * @param start {Object} {lat, lng}
 * @param end {Object} {lat, lng}
 * @returns {number}
 */
const getCoordinatesLengthInPixels = (projection, end, start) => {
  if (start === end) {
    return 0;
  }
  const startPx = projection.fromLatLngToPoint(new window.google.maps.LatLng(start));
  const endPx = projection.fromLatLngToPoint(new window.google.maps.LatLng(end));
  return getLineLengthPx(startPx, endPx);
};

/*const paintNodes = (nodes, gwCount, res = [], tmpGroups = [], gCount = 0, iteration = 1) => {
  if (res.length === 0) {
    nodes.forEach((item) => {
      if (!tmpGroups[item.group] && gCount < gwCount) {
        tmpGroups[item.group] = 1;
        gCount += 1;
        res.push({ ...item, color: "#2F89FC" });
      } else {
        res.push({ ...item });
      }
    });
  } else if (gCount < gwCount) {
    res.forEach((item, key) => {
      if (!item.color && gCount < gwCount) {
        if (tmpGroups[item.group] === iteration - 1 && gCount < gwCount) {
          tmpGroups[item.group] += 1;
          gCount += 1;
          res[key] = { ...item, color: "#2F89FC" };
        } else {
          res[key] = item;
        }
      }
    });
  }
  if ((gCount < gwCount && gCount !== nodes.length ) && iteration < 30) {
    return paintNodes(nodes, gwCount, res, tmpGroups, gCount, iteration += 1);
  }
  return res;
};*/

/*const colors = {
  "0": "red",
  "11": "green",
  "25": "blue",
  "30": "yellow",
  "35": "brown",
  "57": "black",
  "65": "magenta",
  "77": "lightblue",
};*/

const newAllocation = (nodesArray, gwCount, radius, k, projection, DIAMETR_PX, gwCoords) => {
  const circles = [];
  nodesArray.forEach((coords1, key1) => {
    let curr = false;
    gwCoords.forEach((gw) => {
      if (!curr && gw.ck.lat() === coords1.lat() && gw.ck.lng() === coords1.lng()) {
        curr = true;
      }
    });
    if (curr) {
      circles[key1] = {
        coords: { lat: coords1.lat(), lng: coords1.lng() },
        radius: radius * (1 + k),
        color: "#2F89FC",
      };
    } else {
      circles[key1] = {
        coords: { lat: coords1.lat(), lng: coords1.lng() },
        radius: radius * (1 + k),
      };
    }
  });
  return circles;
};

/*const allocation = (nodesArray, gwCount, radius, k, projection, DIAMETR_PX) => {
  const circles = [];
  const groups = [];
  nodesArray.forEach((coords1, key1) => {
    nodesArray.forEach((coords2, key2) => {
      if (!circles[key1]) {
        groups[key1] = 1;
        circles[key1] = {
          coords: {lat: coords1.lat(), lng: coords1.lng()},
          radius: radius * (1 + k),
          group: key1,
          color: colors[key1],
        };
      } else {
        const distance = getCoordinatesLengthInPixels(
          projection,
          { lat: coords1.lat(), lng: coords1.lng()},
          { lat: coords2.lat(), lng: coords2.lng()},
        );
        if (
          key1 !== key2
          && (distance) < (DIAMETR_PX / 2 * Math.floor(nodesArray.length / gwCount))
          && groups[key1] < Math.floor(nodesArray.length / gwCount)
        ) {
          if(!circles[key2]) {
            groups[key1] += 1;
            circles[key2] = {
              coords: {lat: coords2.lat(), lng: coords2.lng()},
              radius: radius * (1 + k),
              group: key1,
              color: colors[key1],
            };
          }
        }
      }
    });
  });
  return paintNodes(circles, gwCount);
};*/

const calcCirclesRectCoords = (mapProjection, polyCoords) => {
  const polyPixelCoords = [];
  polyCoords.forEach((item) => {
    polyPixelCoords.push({
      pixels: mapProjection.fromLatLngToPoint(new window.google.maps.LatLng(item.lat, item.lng))
    });
  });
  let minX, minY, maxX, maxY = 0;
  polyPixelCoords.forEach((item, key) => {
    if (key === 0) {
      minX = maxX = item.pixels.x;
      minY = maxY = item.pixels.y;
    } else {
      if (minX > item.pixels.x) minX = item.pixels.x;
      if (maxX < item.pixels.x) maxX = item.pixels.x;
      if (minY > item.pixels.y) minY = item.pixels.y;
      if (maxY < item.pixels.y) maxY = item.pixels.y;
    }
  });
  const LeftTop = mapProjection.fromPointToLatLng(
    { x: minX, y: minY },
  );
  const LeftBottom = mapProjection.fromPointToLatLng(
    { x: minX, y: maxY },
  );
  const RightTop = mapProjection.fromPointToLatLng(
    { x: maxX, y: minY },
  );
  const RightBottom = mapProjection.fromPointToLatLng(
    { x: maxX, y: maxY },
  );
  return {
    points: { minX: minX - 0.001, maxX: maxX + 0.001, minY: minY - 0.001, maxY: maxY + 0.001 },
    coords: [
      { lat: LeftTop.lat(), lng: LeftTop.lng()},
      { lat: RightTop.lat(), lng: RightTop.lng()},
      { lat: RightBottom.lat(), lng: RightBottom.lng()},
      { lat: LeftBottom.lat(), lng: LeftBottom.lng()},
    ],
  };
};

const getDistances = (coords) => {
  return {
    distanceHorizontal: window.google.maps.geometry.spherical.computeDistanceBetween(
      new window.google.maps.LatLng(coords[0]), new window.google.maps.LatLng(coords[1]),
    ),
    distanceVertical: window.google.maps.geometry.spherical.computeDistanceBetween(
      new window.google.maps.LatLng(coords[0]), new window.google.maps.LatLng(coords[3]),
    ),
  };
};

const calcCoords = (count, poly, points, mapProjection, VERT_POINTS_COUNT, HORIZONT_POINTS_COUNT, radius, lineLetngthHorizontPx, distanceHorizontal, k = 0.25, iteration = 0) => {
  const DIAMETR_PX = radius * lineLetngthHorizontPx / distanceHorizontal * 2;
  const ROW_SHIFT = DIAMETR_PX / 2; // shift for ever even row
  const koeffPX = DIAMETR_PX * k;
  const circlesCoords = [];
  for(let y = 0; y <= VERT_POINTS_COUNT; y += 1) {
    for(let x = 0; x <= HORIZONT_POINTS_COUNT; x += 1) {
      const c = (DIAMETR_PX * y) + koeffPX;
      const sdvig = Math.sqrt(Math.pow(c, 2) - Math.pow(c / 2, 2));
      const yPoint = points.minY + sdvig;

      let xPoint = points.minX + (DIAMETR_PX * x) + koeffPX;
      if (y % 2 === 0) { // shift even row
        xPoint = points.minX + (DIAMETR_PX * x + koeffPX + ROW_SHIFT);
      }
      const curCoord = mapProjection.fromPointToLatLng(
        { x: xPoint, y: yPoint },
      );
      if(isInPoly(curCoord, poly)) {
        circlesCoords.push(curCoord);
      }
    }
  }
  if (iteration < 90) {
    if (circlesCoords.length < parseInt(count)) {  // increasing/decreasing radius
      return calcCoords(count, poly, points, mapProjection, VERT_POINTS_COUNT, HORIZONT_POINTS_COUNT, radius - radius * 0.01, lineLetngthHorizontPx, distanceHorizontal, k += 0.01, iteration += 1);
    }
    if (circlesCoords.length > parseInt(count)) {
      return calcCoords(count, poly, points, mapProjection, VERT_POINTS_COUNT, HORIZONT_POINTS_COUNT, radius + radius * 0.001, lineLetngthHorizontPx, distanceHorizontal, k, iteration += 1);
    }
  }
  if (iteration >= 90 && iteration < 100) {
    if (circlesCoords.length !== parseInt(count)) { // shift
      return calcCoords(count, poly,
        { ...points, minX: points.minX + 0.00001, minY: points.minY + 0.00001},
        mapProjection, VERT_POINTS_COUNT, HORIZONT_POINTS_COUNT, radius, lineLetngthHorizontPx, distanceHorizontal, k, iteration += 1);
    }
  }
  return { circlesCoords, k };
};

const isInPoly = (coords, polygon) => {
  return window.google.maps.geometry.poly.containsLocation(coords, polygon);
};

const placeCircle = (coords, radius, color = false, key, isSatellite = false) => (
  <Circle
    key={key}
    center={coords}
    radius={radius}
    options={{
      strokeColor: color || "#2dbcbc",
      strokeOpacity: isSatellite ? 0.6 : 0.5,
      strokeWeight: isSatellite ? 2 : 1,
      fillColor: color || "#2dbcbc",
      fillOpacity: isSatellite ? 0.2 : 0.1,
    }}
  />
);

const placeInvisiblePoly = (coords) => {
  return new window.google.maps.Polygon({
    // map: map,
    paths: coords,
    strokeColor: "#0000FF",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#0000FF",
    fillOpacity: 0.05,
    draggable: true,
    geodesic: false,
    zIndex: 70,
  });
};

const zoomBounds = (map, circles, padding = false) => {
  const bound = new window.google.maps.LatLngBounds();
  for (let i = 0; i < circles.length; i += 1) {
    bound.extend(new window.google.maps.LatLng(circles[i].lat, circles[i].lng));
  }
  map.fitBounds(bound);
  if (circles.length < 5) {
    map.setZoom(15);
    return;
  }
  if (padding) {
    map.setZoom(map.getZoom() - 0.1);
  }
};

const mapCalc = (proposal, poly, mapProjection, coords, points) => {
  // bandwidth сети (пропускная способность) 1 || 3 || 7
  const bandwidth = usedFor
    .filter((item) => proposal.networkAim.indexOf(item.id) !== -1)
    .sort((a, b) => a.bandwidth < b.bandwidth ? 1 : -1)[0].bandwidth;

  const mbps = connectivityType.find((item) => item.id === proposal.connectionType)
    .mbps;
  const usersCount = proposal.concurrentUsers;

  const squareMeeters = getSquare(poly);
  const squareAcres = squareMeeters / 4047;
  let metersForNode = getAcresPerNode(proposal) * 4047;
  // находим сколько необходимо нод относительно площади
  let count = Math.ceil(squareMeeters / metersForNode); // 2 \ 5 \ 10
  // console.log(count, getAcresPerNode(proposal));

  const countByUsers = Math.ceil(usersCount / 40); // 2 \ 5 \ 10
  if (countByUsers > count) { // количество нод по пользователям больше чем количество по полощади
    // устанавливаем количество нод по пользователям
    count = countByUsers;
    // находим сколько метров надо на ноду при таком количестве
    metersForNode = squareMeeters / countByUsers;
  }

  const gatewaysCount = Math.ceil(bandwidth * (usersCount / mbps));
  if (gatewaysCount > count) { // количество гейтвэев больше чем количество нод
    // устанавливаем количество нод по гейтвэям
    count = gatewaysCount;
    // находим сколько метров надо на ноду при таком количестве
    metersForNode = squareMeeters / count;
  }

  const metersForGw = metersForNode * count / gatewaysCount; // вычисляем сколько нужно площади для одного гейтвэя
  // выисляем предварительный радиус ноды
  const radius = Math.sqrt(metersForNode / Math.PI);
  const radius2 = Math.sqrt(metersForGw / Math.PI); // выисляем предварительный радиус гейтвэя
  const lineLetngthHorizontPx = getCoordinatesLengthInPixels(
    mapProjection,
    coords[0],
    coords[1]
  );
  const { distanceHorizontal, distanceVertical } = getDistances(coords);
  const VERT_POINTS_COUNT = distanceVertical / radius;
  const VERT_POINTS_COUNT2 = distanceVertical / radius2;
  const HORIZONT_POINTS_COUNT = distanceHorizontal / radius;
  const HORIZONT_POINTS_COUNT2 = distanceHorizontal / radius2;

  const { circlesCoords, k } = calcCoords(
    count,
    poly,
    points,
    mapProjection,
    VERT_POINTS_COUNT,
    HORIZONT_POINTS_COUNT,
    radius,
    lineLetngthHorizontPx,
    distanceHorizontal
  );

  const { circlesCoords: circlesCoords2 } = calcCoords(
    gatewaysCount,
    poly,
    points,
    mapProjection,
    VERT_POINTS_COUNT2,
    HORIZONT_POINTS_COUNT2,
    radius2,
    lineLetngthHorizontPx,
    distanceHorizontal
  );
  const gwCoords = []; // тут будут ближайшие к гейтвэям ноды
  circlesCoords2.forEach((ck2, i) => {
    circlesCoords.forEach((ck) => {
      const distance = getCoordinatesLengthInPixels(
        mapProjection,
        { lat: ck2.lat(), lng: ck2.lng()},
        { lat: ck.lat(), lng: ck.lng()},
      );
      if (!gwCoords[i]) {
        gwCoords[i] = { ck, distance };
      } else if (distance < gwCoords[i].distance) {
        gwCoords[i] = { ck, distance };
      }
    });
  });
  const DIAMETR_PX = radius * lineLetngthHorizontPx / distanceHorizontal * 2;
  return {
    squareAcres,
    circlesCoords,
    usersCount,
    gatewaysCount,
    res: newAllocation(circlesCoords, gatewaysCount, radius, k, mapProjection, DIAMETR_PX, gwCoords),
  };
};

export {
  getSquare,
  drawPolyline,
  drawPolygon,
  getAcresPerNode,
  getCoordinatesLengthInPixels,
  calcCirclesRectCoords,
  getDistances,
  calcCoords,
  isInPoly,
  placeInvisiblePoly,
  placeCircle,
  zoomBounds,
  placeRectangle,
  mapCalc,
};
