import { request } from '../../util/requestWrapper';
import { SET_USER_DATA } from '../ActionTypes';

export const saveUser = ({ body }) => {
  return async (dispatch) => {
    try {
      let data = {};
      data = await request({ method: 'PATCH', url: `user`, body: body });
      if (data.id) {
        dispatch({ type: SET_USER_DATA, payload: data });
      }
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].message
          ? error.response.data[0].message
          : error.message;
      console.log(message);
    }
  };
};
