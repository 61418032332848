import React from 'react';
import App from "./containers/App/App";
import { BrowserRouter } from 'react-router-dom';
import configureStore from './redux/store';
import { Provider } from 'react-redux';

export default function Application() {
  const store = configureStore();
  return (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
}
