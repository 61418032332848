import React from "react";
import { render, unmountComponentAtNode } from "react-dom";
import "./confirm.scss";

/*
USAGE
import { confirm } from "../Modals/Confirm";
showDeleteModal = () => {
 confirm({
   confirmActionText: "Delete", // optional
   cancelActionText: "Cancel", // optional
   confirmAction: () => {}, // optional
   cancelAction: () => {}, // optional
   text: "Are you sure you want to delete?", // optional
 });
};
*/

function removeElementReconfirm() {
  const target = document.getElementById("react-confirm-alert");
  document.body.children[1].classList.remove("react-confirm-alert-blur");
  unmountComponentAtNode(target);
  target.parentNode.removeChild(target);
}

function createElementReconfirm(properties) {
  let div = document.getElementById("react-confirm-alert");
  if (div) {
    // Rerender - the mounted ReactConfirmAlert
    render(<ConfirmModal {...properties} />, div);
  } else {
    // Mount the ReactConfirmAlert component
    document.body.children[1].classList.add("react-confirm-alert-blur");
    div = document.createElement("div");
    div.id = "react-confirm-alert";
    document.body.appendChild(div);
    render(<ConfirmModal {...properties} />, div);
  }
}

function ConfirmModal({
  confirmAction, cancelAction,
  text, confirmActionText, cancelActionText,
}) {

  const handleClose = () => {
    removeElementReconfirm();
  };

  return (
    <div className="confirm-modal-wrapper">
      <div className="confirm-modal">
        <div className="modal-title">{text || ""}</div>
        <div className="modal-actions">
          {
            confirmAction && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a className="main-button small-button" onClick={() => { handleClose(); confirmAction(); }}>
                {confirmActionText || "Ok"}
              </a>
            )
          }
          {
            cancelAction && cancelActionText && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a className="main-button small-button cancel-button" onClick={() => { handleClose(); cancelAction(); }}>
                {cancelActionText || "Cancel"}
              </a>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default ConfirmModal;

export function confirm(properties) {
  createElementReconfirm(properties);
}
