import { combineReducers } from 'redux';
import Auth from './Auth';
import Proposal from './Proposal';
import Preview from './Preview';

const func = () =>
  combineReducers({
    auth: Auth,
    proposal: Proposal,
    preview: Preview,
  });
export default func;
