const PageFooter = ({ current = 1, total = 5, text = false }) => {
  return (
    <div className="preview-footer">
      {text && (
        <p>{text}</p>
      )}
      <div className="page-info">
        Page&nbsp;
        <span className="current">{current}&nbsp;</span>
        /&nbsp;
        <span className="total">{total}</span>
      </div>
    </div>
  );
};

export default PageFooter;
