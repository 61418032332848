import React, {useState, useEffect} from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import Arrow from "../../../assets/images/arrow.svg";
import SliderUsersComponent from "./SliderUsersComponent/SliderUsersComponent";

let timer = false;

const logslider = (value) => {
  // position will be between 0 and 100
  const minp = 1;
  const maxp = 100;
  // The result should be between 100 an 10000000
  const minv = Math.log(1);
  const maxv = Math.log(5000000);
  // calculate adjustment factor
  const scale = (maxv-minv) / (maxp-minp);
  return minp + (Math.log(value) - minv) / scale;
};

const logsvalue = (position) => {
  // position will be between 0 and 100
  const minp = 1;
  const maxp = 100;
  // The result should be between 100 an 10000000
  const minv = Math.log(1);
  const maxv = Math.log(5000000);

  // calculate adjustment factor
  const scale = (maxv-minv) / (maxp-minp);
  return Math.round(Math.exp(minv + scale*(position-minp)));
};

const Step4 = ({ inVals, handleNext, handlePrev, saveProposal, dispatchData }) => {
  const [fData, setFData] = useState({concurrentUsers: inVals.concurrentUsers || 1});

  useEffect(() => {
    return (timer) ? clearTimeout(timer) : false;
  });

  const validator = (values) => {
    const errors = {};
    if (!fData.concurrentUsers) {
      errors.concurrentUsers = 'Required';
    } else if (parseInt(values.concurrentUsers) <= 0) {
      errors.concurrentUsers = 'Value should be more than 0';
    }
    return errors;
  };

  const submitter = (values, { setSubmitting }) => {
    saveProposal({ concurrentUsers })
      .then((res) => {
        dispatchData({ concurrentUsers });
        handleNext();
        timer = setTimeout(() => setSubmitting(false), 1000);
      })
      .catch((err) => {
        setSubmitting(false);
      });
  };

  const handleChangeVal = (name, val, clean = false) => {
    setFData({ ...fData, [name]: clean ? val : logsvalue(val) });
  };

  const { concurrentUsers } = fData;
  return (
    <div className="stepper-form-wrapper">
      <p className="stepper-header">Network Use
        <span className="curr-step">4<span className="total-step">/7</span></span>
      </p>
      <p className="stepper-text">Estimate how many users will be connecting to the network
        concurrently. For community networks within the US we typically find 30-40% of
        the area population will use a network currently.</p>
      <Formik
        initialValues={{}}
        validate={validator}
        onSubmit={submitter}
      >
        {({
            isSubmitting,
          }) => (
          <Form className="custom-form" autoComplete="off">
            <div className="input-wrapper">
              <div className="input-label">How many people will connect to the network at the same time?</div>
              <SliderUsersComponent
                type="text"
                value={logslider(concurrentUsers)}
                id="concurrentUsers"
                onChange={(e) => {handleChangeVal("concurrentUsers", e);}}
                className="custom-form-input"
              />
            </div>
            <div className="input-wrapper">
              <input
                value={concurrentUsers}
                onChange={(e) => handleChangeVal("concurrentUsers", e.target.value, true)}
                placeholder="Manual entry" type="concurrentUsers"
                name="concurrentUsers" className="custom-form-input"
              />
              <ErrorMessage name="concurrentUsers" className="error-message" component="div" />
            </div>
            <div className="stepper-buttons-wrapper">
              <button type="button" disabled={isSubmitting} onClick={handlePrev} className="custom-form-button stepper-prev-button">
                <img src={Arrow} alt="prev" />
                Prev
              </button>
              <button type="submit" disabled={isSubmitting} className="custom-form-button stepper-next-button">
                Next
                <img src={Arrow} alt="next" />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Step4;
