import React from 'react';
import classNames from "classnames";
import ReactTooltip from "react-tooltip";
import "./index.scss";

function ControlPanelSetup({
  enabled = false,
  handleUndo,
  undoEnabled,
  handleReset,
  resetEnabled,
  handleCloseShape,
  closeShapeEnabled,
  designButtonEnabled,
  designButtonClick
}) {
  return (
    <div className={classNames("control-panel-wrapper", !enabled && "control-disabled")}>
      <div className="title-block">Coverage Area</div>
      <div className="actions-block">
        <div className={classNames("control-button button-undo", !undoEnabled && "control-button-disabled")} onClick={undoEnabled ? handleUndo : () => {}} />
        <div className={classNames("control-button button-reset", !resetEnabled && "control-button-disabled")} onClick={resetEnabled ? handleReset : () => {}} />
        <div className={classNames("control-button-last control-button button-close", !closeShapeEnabled && "control-button-disabled")} onClick={closeShapeEnabled ? handleCloseShape : () => {}} />
      </div>
      <div data-tip='You need to click the "Close shape" button before the "Design my network"' onClick={designButtonClick} className={classNames("end-block", !designButtonEnabled && "button-disabled")}>Design my network</div>
      {!designButtonEnabled && <ReactTooltip place="top" type="info" effect="solid"/> }
    </div>
  );
}
export default ControlPanelSetup;
