import React, {useEffect, useState} from 'react';
import { Formik, Form } from 'formik';
import Arrow from "../../../assets/images/arrow.svg";
import { powerAvailableList } from "../../../Pages/constants";
import RadioGroupComponent from "./RadioGroupComponent/RadioGroupComponent";

let timer = false;

const Step6 = ({ inVals, handleNext, handlePrev, saveProposal, dispatchData }) => {
  const [fData, setFData] = useState(
    inVals?.id && inVals?.powerType
      ? { powerType: inVals.powerType }
      : { powerType: "no" }
    );

  useEffect(() => {
    return (timer) ? clearTimeout(timer) : false;
  });

  const validator = (values) => {
    const errors = {};
    return errors;
  };

  const { powerType } = fData;

  const submitter = (values, { setSubmitting }) => {
    saveProposal({ powerType })
      .then((res) => {
        dispatchData({ powerType });
        handleNext();
        timer = setTimeout(() => setSubmitting(false), 1000);
      })
      .catch((err) => {
        setSubmitting(false);
      });
  };

  const handleChange = (name, val) => {
    setFData({ ...fData, [name]: val });
  };

  return (
    <div className="stepper-form-wrapper">
      <p className="stepper-header">Power Requirements
        <span className="curr-step">6<span className="total-step">/7</span></span>
      </p>
      <p className="stepper-text">Mesh++ devices come with multiple power options including
        solar, PoE (Power over Ethernet), and direct charging capabilities. They also
        have batteries that will support the device for 3-5 days should power lines go
        down or there be long stretches of limited sunlight. How do you plan to charge
        the Mesh++ Nodes?</p>
      <Formik
        initialValues={{}}
        validate={validator}
        onSubmit={submitter}
      >
        {({ isSubmitting }) => (
          <Form className="custom-form" autoComplete="off">
            <div className="input-wrapper">
              <div className="input-label">What is the power availability at the install locations?</div>
              <RadioGroupComponent
                tags={powerAvailableList}
                selected={powerType}
                handleSelect={(val) => handleChange("powerType", val)}
              />
            </div>
            <div className="stepper-buttons-wrapper">
              <button type="button" disabled={isSubmitting} onClick={handlePrev} className="custom-form-button stepper-prev-button">
                <img src={Arrow} alt="prev" />
                Prev
              </button>
              <button type="submit" disabled={isSubmitting} className="custom-form-button stepper-next-button">
                Next
                <img src={Arrow} alt="next" />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Step6;
