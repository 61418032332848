import React, {useEffect, useState} from 'react';
import { GoogleApiWrapper } from "google-maps-react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Arrow from "../../../assets/images/arrow.svg";
import SliderPersentComponent from "./SliderPersentComponent/SliderPersentComponent";
import getCountryShortName from "../getCountryShortName";

let timer = false;

const Step3 = ({ inVals, handleNext, handlePrev, saveProposal, dispatchData }) => {
  const [fData, setFData] = useState(inVals ? {urbanLevel: inVals.urbanLevel || 50} : {urbanLevel: 50});
  const [cityData, setCityData] = useState(
    !inVals
      ? {address: ""}
      : {
        address: inVals.address || "",
        addressLat: inVals.addressLat,
        addressLng: inVals.addressLng,
        addressShortName: inVals.addressShortName,
    }
  );

  useEffect(() => {
    return (timer) ? clearTimeout(timer) : false;
  });

  const validator = (values) => {
    const { addressLat, addressLng } = cityData;
    const errors = {};
    if (addressLat && addressLng) {
      return {};
    } else if (address === ""){
      errors.address = 'Required';
    } else if (!values.address || values.address.length < 2){
      // errors.address = 'The address name must be at least 2 characters long';
      errors.address = 'Please select an address from the drop-down list';
    }
    return errors;
  };

  const submitter = (values, { setSubmitting }) => {
    // console.log({...fData, ...cityData});
    saveProposal({...fData, ...cityData})
      .then((res) => {
        dispatchData({...fData, ...cityData});
        handleNext();
        timer = setTimeout(() => setSubmitting(false), 1000);
      })
      .catch((err) => {
        setSubmitting(false);
      });
  };

  const handleChangeVal = (name, val) => {
    setFData({ ...fData, [name]: val });
  };
  const { urbanLevel } = fData;

  const onError = (status, clearSuggestions) => {
    console.log(status);
    /*const { form } = this.props;
    const location = form.getFieldValue("location");
    if (status === "ZERO_RESULTS") {
      form.setFields({
        location: {
          value: location,
          errors: [new Error("The location provided can't be found!")],
        },
      });
    } else {
      form.setFields({
        location: { value: location, errors: [new Error(status)] },
      });
    }
    clearSuggestions();*/
  };

  const handleChange = (address) => {
    setCityData({
      address,
      addressLat: 0,
      addressLng: 0,
    });
  };

  const handleSelect = async (address, placeId) => {
    let fullNameplaceId;
    let addressShortName;
    await geocodeByAddress(address)
      .then((results) => {
        fullNameplaceId = results[0].place_id;
        addressShortName = getCountryShortName(results);
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        setCityData({
          addressLat: fullNameplaceId === placeId ? latLng.lat : 0,
          addressLng: fullNameplaceId === placeId ? latLng.lng : 0,
          address: address.trim(),
          addressShortName: addressShortName,
        });
      })
      .catch(error => console.error("Error", error));
  };

  const { address } = cityData;

  return (
    <div className="stepper-form-wrapper">
      <p className="stepper-header">Network Location
        <span className="curr-step">3<span className="total-step">/7</span></span>
      </p>
      <p className="stepper-text">Please insert an address below of where the network will be
        deployed. If you don&#39;t have an exact address you can simply indicate a city or
        town. Then use the slider to let us know how populated and industrial the area is.
        We use this to estimate the likely coverage area per device.</p>
      <Formik
        initialValues={{ address }}
        validate={validator}
        onSubmit={submitter}
      >
        {({ isSubmitting }) => (
          <Form className="custom-form" autoComplete="off">
            <div className="input-wrapper">
              <div className="input-label">Where is this network going to be located?</div>
              <PlacesAutocomplete
                value={address}
                onChange={handleChange}
                onSelect={handleSelect}
                onError={onError}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div className="autocomplete-wrapper">
                    <Field
                      {...getInputProps({
                        placeholder: 'Search Places ...',
                        className: 'location-search-input',
                      })}
                      placeholder="Address" type="address" name="address" className="custom-form-input"
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map(suggestion => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: 'rgba(47, 137, 252, 1)', cursor: 'pointer' }
                          : { backgroundColor: 'rgba(44, 188, 188, 1)', cursor: 'pointer' };
                        return (
                          <div
                            key={suggestion.index}
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              <ErrorMessage name="address" className="error-message" component="div" />
            </div>
            <div className="input-wrapper">
              <SliderPersentComponent
                selected={urbanLevel}
                handleSelect={(val) => handleChangeVal("urbanLevel", val)}
              />
            </div>
            <div className="stepper-buttons-wrapper">
              <button type="button" disabled={isSubmitting} onClick={handlePrev} className="custom-form-button stepper-prev-button">
                <img src={Arrow} alt="prev" />
                Prev
              </button>
              <button type="submit" disabled={isSubmitting} className="custom-form-button stepper-next-button">
                Next
                <img src={Arrow} alt="next" />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCGnoASmLh2aGqkv0hs-dpYaLReMZ_moLk",
  libraries: ["places", "visualization", "geometry"],
})(Step3);
