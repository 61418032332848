import React from "react";
import classNames from "classnames";
import PageFooter from "./Components/PageFooter";
import PageHeader from "./Components/PageHeader";
import "./index.scss";

export default function SolarNodesInstallation({ current, total, proposal }) {
  return (
    <div className="pdf-page-wrapper" id="p3">
      <div className="page-wrapper page3-wrapper">
        <div className={classNames("background-div", proposal.urbanLevel >= 50 && "urban-solar-background")} />
        <PageHeader />
        <div className="preview-content">
          <div className="title">
            <h2>Solar Node Installation Process</h2>
          </div>
          <div className="page-divider" />
          <h4>Mesh++ will supply an on-site technician for the installation. This is slide is purely informative.</h4>
          <div className="preview-list">
            <p>Our 22lb device and 5lb mount can be installed by a single person in 5-10 minutes.</p>

            <p>The mount bracket is installed using only 2 hose clamps. No drilling or pole damage!</p>

            <p>The device mount slides into the bracket and is secured with 4 screws.</p>

            <p>Recommend mounting at any height above 10ft. The angle of the node should always be optimized for latitude and facing south.</p>

            {/*<p>** A bucket truck is not required for installation, but if the city of Champaign has access to one that can be utilized for this project, the installation goes much quicker.</p>*/}
          </div>
        </div>
        <PageFooter  current={current} total={total} />
      </div>
    </div>
  );
}
