import {SET_PREVIEW_DATA, CLEAR_PREVIEW_DATA, SET_PREVIEW_MAP_IMAGE} from '../ActionTypes';

export const setPreview = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_PREVIEW_DATA, payload: data });
    } catch (error) {
      const message = error.message;
      console.log(message);
    }
  };
};

export const setPreviewMapImage = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_PREVIEW_MAP_IMAGE, payload: data });
    } catch (error) {
      const message = error.message;
      console.log(message);
    }
  };
};

export const clearPreview = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CLEAR_PREVIEW_DATA });
    } catch (error) {
      const message = error.message;
      console.log(message);
    }
  };
};
