import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import MainPage from "../../Pages/MainPage";
import MainForm from "../../Pages/MainForm/MainFormComponent";
import MapPage from "../../Pages/Map/MapPage";
import PreviewPage from "../../Pages/Preview/PreviewPage";

const userRoutes = () => (
  <Switch>
    <Route exact path="/" component={MainPage} />
    <Route exact path="/form" component={MainForm} />
    <Route exact path="/map" component={MapPage} />
    <Route exact path="/preview/:uid/:id/:page?" component={PreviewPage} />
    <Redirect from="*" to="/404" />
  </Switch>
);

export default function Router() {
  return (
    <Switch>
      <Route exact path="*" component={userRoutes} />
      {/*<Route exact path="/500" compoennt={Page500} />
      <Route exact path="/404" component={Page404} />*/}
    </Switch>
  );
}
