import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import "./index.scss";

let timer = false;
export default function Loader({ loading }) {
  const [progress, setProgress] = useState(0);
  const pdfGenerated = useSelector(({ proposal }) => proposal.pdfGenerated);

  useEffect(() => {
    if ((pdfGenerated.isFetching || loading) && !timer) {
      timer = setTimeout(function() {
        if (progress < 93) {
          const newProgress = progress + Math.random() * (5 - 1) + 1;
          setProgress(newProgress);
        } else {
          clearTimeout(timer);
        }
      }, 400);
      return () => {
        clearTimeout(timer);
        timer = false;
      };
    }
  }, [loading, progress, pdfGenerated.isFetching]);

  useEffect(() => {
    if (!pdfGenerated.isFetching) {
      setProgress(0);
    }
  }, [pdfGenerated.isFetching]);

  return (
    (loading || pdfGenerated.isFetching) && (
      <div className="loader-wrapper">
        <div className="loading-bar-wrapper">
          <div className="loading-bar" style={{ width: `${progress}%` }} />
        </div>
        Please wait...
      </div>
    )
  );
}
