import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {userLogout} from "../redux/actions";
import {SET_PROPOSAL_DATA, SET_PROPOSALS_DATA} from "../redux/ActionTypes";
import "./index.scss";
import classes from "classnames";
import {isIframe} from "../util/config";

export default function MainPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector(({auth}) => auth);

  const continueProposal = () => {
    history.push({
      pathname: '/form',
      state: {step: 2}
    });
  };

  const newProposal = () => {
    dispatch({ type: SET_PROPOSALS_DATA, payload: [] });
    dispatch({ type: SET_PROPOSAL_DATA, payload: null });
    dispatch(userLogout());
    history.push({
      pathname: '/form',
      state: {step: 0}
    });
  };

  return (
    <div className={classes("main-page-wrapper", isIframe && 'iframe-main-page')}>
      {auth.isLogined ? (
        <>
          <div className="main-button" style={{ marginBottom: "20px" }} onClick={continueProposal}>Resume last design</div>
          <br />
          <div className="main-button" onClick={newProposal}>Create new design</div>
        </>
      ) : (
        <div className="main-button" onClick={() => history.push('/form')}>Create your network</div>
      )}
    </div>
  );
}
