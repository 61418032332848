import React, {useEffect, useState} from 'react';
import { Formik, Form } from 'formik';
import Arrow from "../../../assets/images/arrow.svg";
import RadioGroupComponent from "./RadioGroupComponent/RadioGroupComponent";
import { connectivityType } from "../../constants";

let timer = false;

const Step7 = ({ inVals, handleNext, handlePrev, saveProposal, dispatchData }) => {
  const [fData, setFData] = useState({ connectionType: inVals.connectionType || undefined });

  useEffect(() => {
    return (timer) ? clearTimeout(timer) : false;
  });

  const setInitVals = () => {
    return { connectionType: inVals.connectionType || undefined };
  };
  const validator = (values) => {
    const errors = {};
    const { connectionType } = fData;
    if (!connectionType) {
      errors.connectionType = "Need to select";
    }
    return errors;
  };

  const submitter = (values, { setSubmitting }) => {
    saveProposal({ ...fData })
      .then((res) => {
        dispatchData({...fData});
        handleNext();
        timer = setTimeout(() => setSubmitting(false), 1000);
      })
      .catch((err) => {
        setSubmitting(false);
      });
  };

  const handleChange = (name, val) => {
    setFData({ ...fData, [name]: val });
  };
  const { connectionType } = fData;

  return (
    <div className="stepper-form-wrapper">
      <p className="stepper-header">Backhaul Options
        <span className="curr-step">7<span className="total-step">/7</span></span>
      </p>
      <p className="stepper-text">How will you be supplying connectivity to the mesh network?
        Mesh++ nodes can support a combination of Cellular and wired infrastructure.
        Whatever is being used will be optimized across the network and self heal should
        one source go offline.</p>
      <Formik
        initialValues={setInitVals()}
        validate={validator}
        onSubmit={submitter}
      >
        {({ isSubmitting, errors }) => (
          <Form className="custom-form" autoComplete="off">
            <div className="input-wrapper">
              <div className="input-label">What will be your primary source of connectivity?</div>
              <RadioGroupComponent
                tags={connectivityType}
                selected={connectionType}
                handleSelect={(val) => handleChange("connectionType", val)}
              />
              {!connectionType && <div className="error-message">{errors.connectionType}</div>}
            </div>
            <div className="stepper-buttons-wrapper">
              <button type="button" disabled={isSubmitting} onClick={handlePrev} className="custom-form-button stepper-prev-button">
                <img src={Arrow} alt="prev" />
                Prev
              </button>
              <button type="submit" disabled={isSubmitting} className="custom-form-button stepper-next-button">
                Proceed
                <img src={Arrow} alt="next" />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Step7;
