import React, { useState, useCallback, useEffect } from 'react';
import {useSelector, useDispatch} from "react-redux";
import {GoogleMap, LoadScript} from '@react-google-maps/api';
import mapStyle from "../../../Map/Components/mapStyle";
import "../index.scss";
import {
  drawPolygon,
  calcCirclesRectCoords,
  placeInvisiblePoly,
  placeCircle,
  zoomBounds,
  mapCalc,
} from "../../../Map/Components/mapFunctions";
import { setPreview } from "../../../../redux/actions";
import Loader from "../../../Map/Components/Loader/loader";
import NumberFormat from "react-number-format";
import {googleAPIKey, googleLibraries} from "../../../../util/config";
import DisplaySquare from "../../../Map/Components/DisplaySquare";

let timeout = false;
function MapContainerPreview() {
  const dispatch = useDispatch();
  const proposal = useSelector(({proposal}) => proposal.itemDev);
  const { isSatellite } = useSelector(({proposal}) => proposal.itemDev);
  const [loading, setLoading] = useState(false);
  const [tilesLoaded, setTilesLoaded] = useState(false);
  const [didMount, setDidMount] = useState(false);
  const [mapState, setMapState] = useState({ linePoints: [], state: "polyline", history: [] }); // polyline || polygon
  const [circlesData, setCirclesData] = useState({});

  const [map, setMap] = useState(null);
  const onLoad = useCallback(map => {
    const styledMap = new window.google.maps.StyledMapType(mapStyle, { name: "Styled Map" });
    map.mapTypes.set("styled_map", styledMap);
    map.setMapTypeId("styled_map");
    if (isSatellite) map.setMapTypeId("satellite");
    map.setCenter({ lat: 38.59735822107443, lng: -90.1686734533607 });
    setMap(map);
    window.google.maps.event.addListenerOnce(map, 'tilesloaded', function(){
      //this part runs when the mapobject is created and rendered
      setTilesLoaded(true);
    });
  }, [isSatellite]);

  const calcCircles = useCallback(() => {
    const mapProjection = map.getProjection();
    const  { coords, points } = calcCirclesRectCoords(mapProjection, mapState.linePoints);
    const poly = placeInvisiblePoly(mapState.linePoints);
    const { squareAcres, usersCount, circlesCoords, gatewaysCount, res } = mapCalc(proposal, poly, mapProjection, coords, points);

    dispatch(setPreview({
      squareAcres: squareAcres,
      circles: res,
      nodesNumber: circlesCoords.length,
      usersCount: usersCount,
      gatewaysNumber: gatewaysCount,
      expectedSpeed: gatewaysCount * 350,
    }));
    return {
      squareAcres: squareAcres,
      circles: res,
      nodesNumber: circlesCoords.length,
      usersCount: usersCount,
      gatewaysNumber: gatewaysCount,
      expectedSpeed: gatewaysCount * 350,
    };
  }, [map, mapState.linePoints, proposal, dispatch]);

  useEffect(() => {
    if (mapState.state === "design") {
      setLoading(false);
      setCirclesData(calcCircles());
    }
  }, [mapState.state, calcCircles]);

  useEffect(() => {
    if (map && proposal && !didMount) {
      if (proposal?.mapData?.polygon?.length) {
        if (mapState.state !== "polygon"){
          setLoading(true);
          setMapState({
            ...mapState,
            linePoints: proposal?.mapData?.polygon,
            state: "polygon",
          });
          if (timeout) clearTimeout(timeout);
          timeout = setTimeout(() => {
            setMapState({
              ...mapState,
              linePoints: proposal?.mapData?.polygon,
              state: "design",
            });
            setDidMount(true);
          }, 1000);
        }
        if(proposal.mapData.nodes.length > 0) {

          zoomBounds(
            map,
            proposal.mapData.nodes,
            {
              bottom: 20,
              left: 20,
              right: 20,
              top: 20,
            },
          );
        } else {
          map.setCenter({ lat: parseFloat(proposal.addressLat), lng: parseFloat(proposal.addressLng) });
        }
      }
    }
  }, [proposal, map, mapState, didMount, calcCircles]);

  const onUnmount = useCallback(map => {
    setMap(null);
  }, []);

  const placeCircles = () => {
    const res = [];
    circlesData.circles.forEach((item, key) => {
      res.push(
        placeCircle(
          item.coords,
          item.radius,
          item.color,
          key,
          isSatellite,
        )
      );
    });
    return [...res];
  };

  return (
    <div className="map-wrapper" id="mapWrapper">
      <Loader loading={loading} />
      <LoadScript
        libraries={googleLibraries}
        googleMapsApiKey={googleAPIKey}
      >
        <GoogleMap
          mapContainerClassName="gmap"
          zoom={25}
          options={{
            minZoom: 3,
            gestureHandling: "cooperative",
            scrollwheel: false,
            keyboardShortcuts: false,
            zoomControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            draggable: false,
          }}
          onLoad={onLoad}
          onUnmount={onUnmount}
          libraries={["geometry", "places", "visualization"]}
        >
          {(mapState.state === 'polygon') && drawPolygon(mapState.linePoints)}
          {mapState.state === 'design' && circlesData.circles && placeCircles()}
        </GoogleMap>
      </LoadScript>
      {mapState.state === 'design' && (
        <div className="legend">
          {tilesLoaded && <div id="pixel" style={{ width: "1px", height: "1px", backgroundColor: "white" }} />}
          <div className="info-wrapper">
            <div>
              <span className="count">{circlesData.nodesNumber ? <NumberFormat displayType={'text'} thousandSeparator="," value={circlesData.nodesNumber} /> : 0}</span>
              <span className="info-title">Nodes</span>
            </div>
          </div>
          <div className="info-wrapper">
            <div>
              <span className="count">{circlesData.gatewaysNumber ? <NumberFormat displayType={'text'} thousandSeparator="," value={circlesData.gatewaysNumber} /> : 0}</span>
              <span className="info-title">Gateways</span>
            </div>
          </div>
          <div className="info-wrapper">
            <div>
              <span className="count">{circlesData.expectedSpeed ? <NumberFormat displayType={'text'} thousandSeparator="," value={circlesData.expectedSpeed} /> : 0}Mbps</span>
              <span className="info-title">Network throughput</span>
            </div>
          </div>
          <div className="info-wrapper">
            <div>
              <span className="count">{circlesData.nodesNumber ? <NumberFormat displayType={'text'} thousandSeparator="," value={circlesData.nodesNumber * 40} /> : 0}</span>
              <span className="info-title">Users</span>
            </div>
          </div>
          <div className="info-wrapper">
            <div>
              <span className="count">
                {
                  circlesData.squareAcres
                    ? <DisplaySquare proposal={proposal} valueAcres={circlesData.squareAcres.toFixed(0)} hideUnits />
                    : 0
                }
              </span>
              <span className="info-title">{proposal.addressShortName && proposal.addressShortName === "US" ? "Acres" : "Meeters"} Coverage</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );

}

export default MapContainerPreview;
