import React from "react";
import classNames from "classnames";
import PageFooter from "./Components/PageFooter";
import PageHeader from "./Components/PageHeader";
import "./index.scss";

export default function SolarNodes({ current, total }) {
  return (
    <div className="pdf-page-wrapper" id="p3">
      <div className="page-wrapper page3-wrapper">
        <div className={classNames("background-div")} />
        <PageHeader />
        <div className="preview-content">
          <div className="title">
            <h2>S618: Solar-Powered Nodes Can Go anywhere</h2>
          </div>
          <div className="page-divider" />
          <h4>No cables or power required.</h4>
          <div className="preview-list">
            <ul>
              <li>Range up to 150m line-of-sight</li>
              <li>Supports 25-500 users</li>
              <li>22lbs (10kg)</li>
              <li>4.3 x 4.3 x 25.2 in (110 x 110 x 640mm) without solar panel</li>
              <li>Fully solar-powered</li>
              <li>Weatherproof</li>
              <li>Snapdragon processor</li>
              <li>2x2 dual-band WiFi 6</li>
              <li>Optional 4x4 WiFi 6E</li>
              <li>Bluetooth activation</li>
              <li>4-day battery reserve</li>
              <li>Optional Cellular: Up to CAT20 4G-LTE</li>
              <li>Optional Local Storage: Up to 1TB internal SSD</li>
            </ul>
          </div>
        </div>
        <PageFooter  current={current} total={total} />
      </div>
    </div>
  );
}
