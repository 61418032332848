import React from "react";
import PageFooter from "./Components/PageFooter";
import PageHeader from "./Components/PageHeader";
import { powerAvailableList, usedFor } from "../../../Pages/constants";
import "./index.scss";
import NumberFormat from "react-number-format";

export default function InputData({ current, total, proposal }) {
  return (
    <div className="pdf-page-wrapper" id="p4">
      <div className="page-wrapper page2-wrapper">
        <div className="background-div" />
        <PageHeader isLightPage={true} />
        <div className="preview-content">
          <div className="title">
            <h2>Input data</h2>
          </div>
          <div className="page-divider" />
          <div className="content">
            <ul>
              <li>Address: <strong>{proposal.address}</strong></li>
              <li>Location:  <strong>Rural {100-(proposal.urbanLevel || 0)} / {proposal.urbanLevel || 0} Urban</strong></li>
              <li>Concurrent Users:  <strong><NumberFormat displayType={'text'} thousandSeparator="," value={proposal.concurrentUsers} /></strong></li>
              <li>Connection Type:  <strong>{proposal.connectionType}</strong></li>
              <li>Available Power Type:  <strong>{powerAvailableList.find((item) => item.id === proposal.powerType).title}</strong></li>
              <li>Network Use:  <strong>{usedFor.filter((item) => proposal.networkAim.indexOf(item.id) !== -1).map(item => item.title).join(", ")}</strong></li>
            </ul>
          </div>
        </div>
        <PageFooter current={current} total={total} />
      </div>
    </div>
  );
}
