import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import PhoneInput from 'react-phone-input-2';
import Arrow from "../../../assets/images/arrow.svg";
import {userSignIn, saveUser} from "../../../redux/actions";

let timer = false;

const Step2 = ({ inVals, handleNext, handlePrev, dispatchData, isLogined }) => {
  const auth = useSelector(({auth}) => auth);
  const dispatch = useDispatch();
  const setInitVals = () => {
    return (inVals.email)
      ? inVals
      : { ...inVals, email: '', phone: '' };
  };

  useEffect(() => {
    return (timer) ? clearTimeout(timer) : false;
  });


  const validator = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    return errors;
  };

  const submitter = (values, { setSubmitting }) => {
    if (auth.isLogined) {
      dispatch(saveUser({ body: values })).then(() => {
        dispatchData(values);
        handleNext();
        timer = setTimeout(() => setSubmitting(false), 1000);
      })
      .catch((err) => {
        setSubmitting(false);
      });
      return;
    }
    dispatch(userSignIn(values))
      .then((res) => {
        dispatchData(values);
        handleNext();
        timer = setTimeout(() => setSubmitting(false), 1000);
      })
      .catch((err) => {
        setSubmitting(false);
      });
  };

  return (
    <div className="stepper-form-wrapper">
      <p className="stepper-header">General questions
        <span className="curr-step">2<span className="total-step">/7</span></span>
      </p>
      {/*<p className="stepper-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa dui tristique in fames.</p>*/}
      <Formik
        initialValues={setInitVals()}
        validate={validator}
        onSubmit={submitter}
      >
        {({
          isSubmitting,
          handleChange,
          handleBlur,
          errors,
          touched,
        }) => (
          <Form className="custom-form" autoComplete="off">
            <div className="input-wrapper">
              <div className="input-label">How can we contact you?</div>
              <Field  disabled={isLogined} placeholder="Email" type="email" name="email" className="custom-form-input" />
              <ErrorMessage name="email" className="error-message" component="div" />
            </div>
            <div className="input-wrapper">
              <Field
                placeholder="Phone"
                type="phone"
                name="phone"
                id="phone"
                className="custom-form-input"
              >
                {({ field }) => (
                  <PhoneInput
                    {...field}
                    inputProps={{ id: "phone" }}
                    country={'us'}
                    name="phone"
                    id="phone"
                    // onBlur={handleBlur}
                    placeholder="Enter your phone number"
                    type="text"
                    className="custom-form-input"
                    onChange={(e) => field.onChange({ target: { name: "phone", value: e } } )}
                  />
                )}
              </Field>
              <ErrorMessage name="phone" className="error-message" component="div" />
            </div>
            <div className="stepper-buttons-wrapper">
              <button type="button" disabled={isSubmitting} onClick={handlePrev} className="custom-form-button stepper-prev-button">
                <img src={Arrow} alt="prev" />
                Prev
              </button>
              <button type="submit" disabled={isSubmitting} className="custom-form-button stepper-next-button">
                Next
                <img src={Arrow} alt="next" />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Step2;
