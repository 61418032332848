import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import classNames from 'classnames';
import {apiHost, googleAPIKey, googleLibraries, isIframe} from '../../../util/config';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import {
  scheduleProposalCall,
  saveProposal,
  generateProposalPdf,
  sendProposalPdf,
  saveProposalMapType,
} from '../../../redux/actions';
import ControlPanelSetup from './ControlPanel/ControlPanelSetup';
import mapStyle from './mapStyle';
import './index.scss';
import ControlPanelResult from './ControlPanel/ControlPanelResult';
import {
  getSquare,
  drawPolyline,
  drawPolygon,
  calcCirclesRectCoords,
  placeInvisiblePoly,
  placeCircle,
  zoomBounds, mapCalc,
} from './mapFunctions';
import Loader from "./Loader/loader";
import {confirm} from "../../../Components/confirm";
import DisplaySquare from "./DisplaySquare";
let timeout = false;
let isSatelliteIncome = false;

function MapContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const proposal = useSelector(({ proposal }) => proposal.item);
  const isFetching = useSelector(({ proposal }) => proposal.isFetching);
  const pdfGenerated = useSelector(({ proposal }) => proposal.pdfGenerated);
  const pdfSended = useSelector(({ proposal }) => proposal.pdfSended);
  const callScheduled = useSelector(({ proposal }) => proposal.callScheduled);
  const auth = useSelector(({ auth }) => auth);
  const [tilesLoaded, setTilesLoaded] = useState(false);
  const [isSatellite, setIsSatellite] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [drawMode, setDrawMode] = useState(true);
  const [didMount, setDidMount] = useState(false);

  const [map, setMap] = useState(null);
  const onLoad = useCallback(map => {
    const styledMap = new window.google.maps.StyledMapType(mapStyle, {
      name: 'Styled Map',
    });
    map.mapTypes.set('styled_map', styledMap);
    map.setMapTypeId('styled_map');
    map.setCenter({ lat: 38.59735822107443, lng: -90.1686734533607 });
    setMap(map);
  }, []);

  useEffect(() => {
    if (proposal) {
      isSatelliteIncome = proposal.isSatellite;
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (proposal && map) {
      setIsSatellite(proposal.isSatellite);
      if (proposal.isSatellite) {
        map.setMapTypeId('satellite');
      }
    }
  }, [proposal, map]);

  const [mapState, setMapState] = useState({
    linePoints: [],
    state: 'polyline',
    history: [],
  }); // polyline || polygon
  const [circlesData, setCirclesData] = useState({});

  useEffect(() => {
    if (!auth.isLogined) window.location.href = "/";
  }, [auth]);

  const getAcresSquare = () => {
    const poly = placeInvisiblePoly(mapState.linePoints);
    return (getSquare(poly) / 4047).toFixed(0);
  };

  useEffect(() => {
    if (mapState.linePoints.length > 0 && drawMode === false) {
      setDrawMode(true);
    }
  },  [mapState.linePoints.length, drawMode]);

  const calcCircles = useCallback(() => {
    const mapProjection = map.getProjection();
    const { coords, points } = calcCirclesRectCoords(mapProjection, mapState.linePoints);
    const poly = placeInvisiblePoly(mapState.linePoints);
    const { usersCount, circlesCoords, gatewaysCount, res } = mapCalc(proposal, poly, mapProjection, coords, points);
    return {
      circles: res,
      nodesNumber: circlesCoords.length,
      usersCount: usersCount,
      gatewaysNumber: gatewaysCount,
      expectedSpeed: gatewaysCount * 350,
    };
  }, [map, mapState.linePoints, proposal]);

  useEffect(() => {
    if (mapState.state === 'design') {
      setLoading(false);
      setCirclesData(calcCircles());
    }
  }, [mapState.state, calcCircles]);

  useEffect(() => {
    if (map && proposal && !didMount) {
      if (proposal?.mapData?.polygon?.length) {
        if (mapState.state !== 'polygon') {
          setLoading(true);
          setMapState({
            ...mapState,
            linePoints: proposal?.mapData?.polygon,
            state: 'polygon',
          });
          if (timeout) clearTimeout(timeout);
          timeout = setTimeout(() => {
            setMapState({
              ...mapState,
              linePoints: proposal?.mapData?.polygon,
              state: 'design',
            });
            setDidMount(true);
            if (proposal?.mapData?.polygon === 0) {
              setDrawMode(false);
            }
          }, 1000);
        }
        if (proposal.mapData.nodes.length > 0) {
          zoomBounds(map, proposal.mapData.nodes);
        }
      } else if (!didMount) {
        map.setCenter({
          lat: parseFloat(proposal.addressLat),
          lng: parseFloat(proposal.addressLng),
        });
        setDrawMode(false);
        setDidMount(true);
      }
    }
  }, [proposal, map, mapState, didMount, calcCircles]);

  const calcCirclesNew = () => {
    const mapProjection = map.getProjection();
    const { coords, points } = calcCirclesRectCoords(mapProjection, mapState.linePoints);
    const poly = placeInvisiblePoly(mapState.linePoints);
    const { usersCount, circlesCoords, gatewaysCount, res } = mapCalc(proposal, poly, mapProjection, coords, points);
    return {
      circles: res,
      nodesNumber: circlesCoords.length,
      usersCount: usersCount,
      gatewaysNumber: gatewaysCount,
      expectedSpeed: gatewaysCount * 350,
    };
  };

  const onUnmount = useCallback(map => {
    setMap(null);
  }, []);

  /**
   * add point to the points array
   * @param event
   */
  const addPoint = (event) => {
    const latLng = {};
    latLng.lat = event.latLng.lat();
    latLng.lng = event.latLng.lng();
    const newHistory = [
      ...mapState.history,
      { state: mapState.state, linePoints: mapState.linePoints },
    ];
    setMapState({
      ...mapState,
      state: mapState.state === 'polygon' ? 'polyline' : mapState.state,
      linePoints: [...mapState.linePoints, latLng],
      history: newHistory,
    });
  };

  const handleUndo = () => {
    if (mapState.history.length === 0) return;
    const historyPoint = mapState.history.splice(mapState.history.length - 1, 1)[0];
    setMapState({
      ...historyPoint,
      history: mapState.history,
    });
  };

  const handleChangePoly = () => {
    setMapState({
      ...mapState,
      state: 'polygon',
    });
  };

  const handleReset = () => {
    setMapState({ state: 'polyline', linePoints: [], history: [] });
    setDrawMode(false);
  };

  const handleCloseShape = () => {
    if (mapState.state === 'polyline') {
      const newHistory = [
        ...mapState.history,
        { state: mapState.state, linePoints: mapState.linePoints },
      ];
      setMapState({
        ...mapState,
        state: 'polygon',
        history: newHistory,
      });
    }
  };

  const getOpacity = () => {
    return isSatellite;
  };

  const placeCircles = () => {
    const res = [];
    circlesData.circles.forEach((item, key) => {
      res.push(placeCircle(item.coords, item.radius, item.color, key, getOpacity(item.color)));
    });
    return [...res];
  };

  const markerDragEnd = (newCoords, oldCoords, key) => {
    const coordsAfterMove = mapState.linePoints.map((item, k) =>
      k !== key ? item : { lat: newCoords.lat(), lng: newCoords.lng() }
    );
    const newHistory = [
      ...mapState.history,
      { state: mapState.state, linePoints: mapState.linePoints },
    ];
    setMapState({
      ...mapState,
      linePoints: coordsAfterMove,
      history: newHistory,
    });
  };

  const getMarker = (position, key) => {
    const icon = {
      anchor: new window.google.maps.Point(10, 10),
      url: 'data:image/svg+xml;base64,{{url}}',
    };
    const url = `
<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
<circle cx="10" cy="10" r="5" stroke-opacity="0.7" stroke="#2F89FC" stroke-width="2" fill="#fff"/>
</svg>`;
    icon.url = icon.url.replace('{{url}}', window.btoa(url));
    return (
      <Marker
        key={key}
        position={position}
        icon={icon}
        draggable={true}
        onDragEnd={(e) => markerDragEnd(e.latLng, position, key)}
      />
    );
  };

  const handleClickDownload = async (generate = false, tryouts = 0) => {
    setDownloading(true);
    if (proposal.isSatellite !== isSatelliteIncome || generate) {
      setSaving(true);
      isSatelliteIncome = proposal.isSatellite;
      await dispatch(generateProposalPdf({ id: proposal.id }))
        .then(() => setSaving(false))
        .catch(() => setSaving(false));
    }
    await axios(`${apiHost}/me/proposals/${proposal.id}/download?timestamp=${new Date().getTime()}`, {
      method: 'GET',
      responseType: 'arraybuffer',
      headers: {
        Pragma: 'no-cache',
        Authorization: `${localStorage.getItem('accessToken')}`,
      },
    })
      .then((response) => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        setDownloading(false);
        window.open(fileURL);
      })
      .catch((error) => {
        setDownloading(false);
        if (error.response.status === 404 && tryouts < 3) {
          handleClickDownload(true, tryouts + 1);
        }
      });
  };

  const handleScheduleCall = () => {
    dispatch(
      scheduleProposalCall({
        id: proposal.id,
      })
    ).then((res) => {
      if (res?.data === true) {
        confirm({
          confirmActionText: "Ok", // optional
          confirmAction: () => {}, // optional
          text: `We have received your request and will contact you as soon as possible.`, // optional
        });
      }
    }).catch(err => {
      if (err.message === "File not found") {
        confirm({
          confirmActionText: "Edit map", // optional
          cancelActionText: "Cancel", // optional
          cancelAction: () => {}, // optional
          confirmAction: handleChangePoly, // optional
          text: "There is some error with your proposal, please edit polygon and try again.", // optional
        });
      } else {
        confirm({
          confirmActionText: "Edit phone", // optional
          cancelActionText: "Cancel", // optional
          cancelAction: () => {}, // optional
          confirmAction: () => history.push({ pathname: '/form', state: {step: 1} }) , // optional
          text: err.message, // optional
        });
      }
    });
  };

  const handleClickPreview = () => {
    const link = document.createElement('a');
    link.href = `/preview/${auth.id}/${proposal.id}`;
    link.target = '_blank';
    link.click();
    link.remove();
  };

  const savePolygonData = () => {
    setSaving(true);
    const data = calcCirclesNew();
    setCirclesData(data);
    const mapData = {
      polygon: mapState.linePoints,
      nodes: data.circles.map((node) => ({
        ...node.coords,
        type: node.color ? 'gateway' : '',
      })),
    };
    if (proposal) {
      dispatch(
        saveProposal({
          id: proposal.id,
          body: {
            ...proposal,
            nodesNumber: data.nodesNumber,
            gatewaysNumber: data.gatewaysNumber,
            expectedSpeed: data.expectedSpeed,
            isSatellite,
            mapData,
          },
        })
      ).then(() => {
        dispatch(generateProposalPdf({ id: proposal.id }))
          .then(() => setSaving(false))
          .catch(() => setSaving(false));
      });
    }
    setMapState({ ...mapState, state: 'design' });
  };

  const sendEmail = () => {
    dispatch(sendProposalPdf({ id: proposal.id }))
      .then((res) => {
        if (res?.data === true) {
          confirm({
            confirmActionText: "Ok", // optional
            confirmAction: () => {}, // optional
            text: `The Proposal has been sent to your email ${auth.email}`, // optional
          });
        }
      });
  };

  const setDraw = () => {
    setDrawMode(true);
  };

  const submitMapType = (mapTypeId) => {
    dispatch(
      saveProposalMapType({
        id: proposal.id,
        body: {
          isSatellite: mapTypeId,
        },
      })
    );
  };

  const mapTypeChange = () => {
    if (isSatellite) setIsSatellite(false);
    else setIsSatellite(true);
    submitMapType(!isSatellite);
  };

  return (
    <div className={classNames("map-page", isIframe && "map-page-iframe")}>
      <div className={classNames("map-wrapper", !drawMode && "map-wrapper-start")} id="mapWrapper">
        <Loader loading={loading || saving} />
        {(mapState.state === 'polyline' || mapState.state === 'polygon') && (
          <ControlPanelSetup
            enabled={map}
            undoEnabled={mapState.history.length > 0}
            handleUndo={handleUndo}
            handleReset={handleReset}
            resetEnabled={mapState.linePoints.length > 0}
            designButtonEnabled={mapState.state === 'polygon'}
            designButtonClick={savePolygonData}
            handleCloseShape={handleCloseShape}
            closeShapeEnabled={
              mapState.state === 'polyline' && mapState.linePoints.length > 2
            }
          />
        )}
        {mapState.state === 'design' && (
          <ControlPanelResult
            isDownloading={downloading}
            backButtonClick={handleChangePoly}
            handleDownload={() => handleClickDownload()}
            handleScheduleCall={handleScheduleCall}
            handleSend={sendEmail}
            handlePreview={handleClickPreview}
            buttonsEnabled={
              ((!pdfGenerated.isFetching && !pdfGenerated.status) ||
              (pdfGenerated.status && !pdfGenerated.isFetching))
            }
            isSendEnabled={
              ((!pdfGenerated.isFetching && !pdfGenerated.status) ||
                (pdfGenerated.status && !pdfGenerated.isFetching)) && !pdfSended.isFetching
            }
            isScheduleEnabled={
              ((!callScheduled.isFetching && !callScheduled.status) ||
                (callScheduled.status && !callScheduled.isFetching)) && !callScheduled.isFetching
            }
            data={{
              ...circlesData,
            }}
          />
        )}
        <LoadScript
          libraries={googleLibraries}
          googleMapsApiKey={googleAPIKey}
        >
          <GoogleMap
            mapContainerClassName="gmap"
            zoom={15}
            options={{
              minZoom: 3,
              gestureHandling: 'cooperative',
              scrollwheel: true,
              keyboardShortcuts: false,
              zoomControl: mapState.state === 'design' ? false : true,
              fullscreenControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
            }}
            mapTypeId={isSatellite ? 'satellite' : "styled_map" }
            onTilesLoaded={() => setTilesLoaded(true)}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onClick={
              mapState.state === 'polyline' || mapState.state === 'polygon'
                ? addPoint
                : () => {}
            }
            libraries={['geometry', 'places', 'visualization']}
          >
            {(mapState.state === 'polyline' || mapState.state === 'polygon') &&
              mapState.linePoints.map((item, key) => getMarker(item, key))}
            {mapState.state === 'polyline' &&
              mapState.linePoints.length > 1 &&
              drawPolyline(mapState.linePoints)}
            {mapState.state === 'polygon' && drawPolygon(mapState.linePoints)}
            {/*{mapState.state === 'design' && placeRectangle(mapState.linePoints)}*/}
            {mapState.state === 'design' && circlesData.circles && placeCircles()}
            {mapState.state === 'design' && (
              <div className="legend">
                <span className="sq">
                  <DisplaySquare proposal={proposal} valueAcres={getAcresSquare()}/>
                </span>
                <span>Repeater</span>
                <span className="gateway">Gateway</span>
              </div>
            )}
            <div className={`map-type-control ${isFetching ? "disabled" : ""}` } onClick={!isFetching ? mapTypeChange : () =>{}}>
              {isSatellite ? "Simple" : "Satellite"}
            </div>
            {mapState.state === 'polygon' && mapState.linePoints.length > 0 && tilesLoaded && (
              <div className="legend">
                <span className="sq">
                  <DisplaySquare proposal={proposal} valueAcres={getAcresSquare()}/>
                </span>
              </div>
            )}
          </GoogleMap>
        </LoadScript>
        <div className="center-text">
          Click map to define boundary.
          <br /><br />
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a onClick={setDraw} className="main-button">Start</a>
        </div>
      </div>
    </div>
  );
}

export default MapContainer;
