import React from 'react';
import './styles.scss';
import { Slider } from "rsuite";

const SliderPersentComponent = ({ labels, selected, handleSelect }) => {
  const handleStyle = {
    color: '#fff',
    fontSize: 12,
    width: 32,
    height: 22
  };

  return (
    <div className="slider-wrapper">
      <div className="slider-points">
        <span>Rural</span>
        <span>{100-(selected || 0)} / {selected || 0}</span>
        <span>Urban</span>
      </div>
      <div className="slider">
        <Slider
          min={0}
          max={100}
          value={selected}
          className="custom-slider"
          handleStyle={handleStyle}
          tooltip={false}
          handleTitle={false}
          onChange={handleSelect}
        />
      </div>
    </div>
  );
};

export default SliderPersentComponent;
