import { SET_PREVIEW_MAP_IMAGE, SET_PREVIEW_DATA, CLEAR_PREVIEW_DATA } from '../ActionTypes';

const INIT_STATE = {
  mapImage: "",
  previewCalc: null,
  previewData: null,
};

const state = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_PREVIEW_DATA: {
      return {
        ...state,
      };
    }
    case SET_PREVIEW_DATA: {
      return {
        ...state,
        previewData: action.payload,
      };
    }
    case SET_PREVIEW_MAP_IMAGE: {
      return {
        ...state,
        mapImage: action.payload,
      };
    }
    default:
      return state;
  }
};

export default state;
