import React from "react";
import blue from "../../../assets/images/forPdf/circle-blue.svg";
import green from "../../../assets/images/forPdf/circle-green.svg";
import "./index.scss";
import MapContainerPreview from "./Components/MapContainerPreview";
import PageFooter from "./Components/PageFooter";
import PageHeader from "./Components/PageHeader";

export default function MapArea({ current, total }) {
  return (
    <div className="pdf-page-wrapper" id="p2">
      <div className="page-wrapper page2-wrapper">
        <div className="background-div" />
        <PageHeader isLightPage={true} />
        <div className="preview-content">
          <div className="title">
            <h2>Coverage area map</h2>
            <div className="info-block">
              <span><img alt="Repeater" src={green} />Repeater</span>
              <span><img alt="Gateway" src={blue} />Gateway</span>
            </div>
          </div>
          <MapContainerPreview />
        </div>
        <PageFooter current={current} total={total} />
      </div>
    </div>
  );
}
