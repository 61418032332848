import React  from 'react';
import classes from "classnames";

const RadioGroupComponent = ({ tags = [], selected, handleSelect }) => {

  const itemClick = (itemId) => {
    handleSelect(itemId);
  };

  const item = (data) => (
    <div className="checkbox-wrapper" key={data.id}>
      <div className="checkmark-container">
        <input
          onChange={() => itemClick(data.id)}
          checked={selected && selected === data.id ? "checked" : ""}
          type="checkbox"
          name="enabled"
          className="form-checkbox"
        />
        <span className={classes("checkmark", selected && selected === data.id && "checked")} />
        {selected === "None" && data.id === "None" && (
          <div className="bubble-wrapper">
            <div className="bubble">Mesh++ can only expand connectivity, not create it. The team will contact you shortly with fiber options nearby!</div>
          </div>
        )}
      </div>
      <p>{data.title}</p>
    </div>
  );
  return (
    <div className="radio-list">
      {tags.map(data => item(data))}
    </div>
  );
};

export default RadioGroupComponent;
