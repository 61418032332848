import React from "react";
import PageFooter from "./Components/PageFooter";
import PageHeader from "./Components/PageHeader";
import "./index.scss";

export default function ComingSoon({ current, total, proposal }) {
  return (
    <div className="pdf-page-wrapper" id="p4">
      <div className="page-wrapper page2-wrapper">
        <div className="background-div" />
        <PageHeader isLightPage={true} />
        <div className="preview-content">
          <div className="title">
            <h2>S618 Specs</h2>
          </div>
          <div className="content">
            <h4>Hardware</h4>
            <ul>
              <li>CPU: 1.8GHz quad-core ARMv7</li>
              <li>Memory: 1GB (optional to 2GB)</li>
              <li>Storage: 32MB NOR flash, 128GB NAND flash, optional eMMC, microSD</li>
              <li>2.4GHz: 2x2 802.11b/g/n/ax 27dBm/chain (574Mbps) - internal antennas</li>
              <li>5GHz: 2x2 802.11a/n/ac/ax 25dBm/chain (1201Mbps) - internal antennas</li>
              <li>[Optional] 6GHz: 4x4 802.11ax 23dBm/chain (5Gbps) - external N-type antennas</li>
              <li>[Optional] Cellular: CAT4-CAT20 4G LTE or sub-6GHz 5G dual-SIM - internal 2x2 antennas</li>
              <li>Bluetooth 5</li>
              <li>Peripherals: 2x USB 2.0, RJ45 GbE LAN/WAN</li>
              <li>Internal peripherals: microSD, GPS, [optional] LoraWAN, [optional] 2.5GbE</li>
            </ul>
          </div>
          <div className="content">
            <h4>Software</h4>
            <ul>
              <li>Linux Kernel: 5.10</li>
              <li>Driver: Ath11k</li>
              <li>Security: WPA/WPA2/WPA2-Enterprise</li>
            </ul>
          </div>
          <div className="content">
            <h4>Physical</h4>
            <ul>
              <li>Size: 110mm diameter * 640mm length + solar panel (external)</li>
              <li>Weight: 7kg</li>
              <li>Ingress protection: IP68</li>
              <li>Temperature: -20C to 70C (storage), 0C to 50C (operating, internal), -10C to 30C (operating, ambient)</li>
              <li>Battery: 18650 lithium-ion, 894Wh stock (up to 1.2kWh)</li>
            </ul>
          </div>
        </div>
        <PageFooter current={current} total={total} />
      </div>
    </div>
  );
}
