import PageLogo from "./PageLogo";
import moment from "moment";

const PageHeader = ({isLightPage}) => {
  return (
    <div className="preview-topbar">
      <PageLogo isLightPage={isLightPage} />
      <span className="date-info">{moment().format("DD.MM.YYYY")}</span>
    </div>
  );
};

export default PageHeader;
