import React from 'react';
import PageFooter from "./Components/PageFooter";
import PageHeader from "./Components/PageHeader";
import "./index.scss";

export default function CostDescr_NoPrices({ current, total }) {

  return (
    <div className="pdf-page-wrapper" id="p4">
      <div className="page-wrapper page2-wrapper">
        <div className="background-div" />
        <PageHeader isLightPage={true} />
        <div className="preview-content">
          <p style={{ color: "black", textAlign: "center", fontSize: "20px" }}>Please contact us to get detailed estimate. info@meshplusplus.com</p>
        </div>
        <PageFooter  current={current} total={total} text="A Mesh++ Engineer will be in touch with a detailed estimate. info@meshplusplus.com" />
      </div>
    </div>
  );
}
