import { CLEAR_SESSION, SET_USER_DATA, START_SESSION } from '../ActionTypes';

const INIT_STATE = {
  isLogined: !!localStorage.getItem('accessToken'),
  id: null,
  accessToken: null,
  refreshToken: null,
  userData: null,
};

const state = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_SESSION: {
      return {
        ...state,
        userData: null,
        isLogined: false,
      };
    }
    case START_SESSION: {
      return {
        ...state,
        isLogined: true,
      };
    }
    case SET_USER_DATA: {
      return {
        ...state,
        ...action.payload,
        userData: {
          name: action?.payload?.name,
          email: action?.payload?.email,
          phone: action?.payload?.phone,
          company: action?.payload?.company,
        }
      };
    }
    default:
      return state;
  }
};

export default state;
