import React from "react";
import PageFooter from "./Components/PageFooter";
import PageHeader from "./Components/PageHeader";
import "./index.scss";

export default function AppDashboard({ current, total }) {
  return (
    <div className="pdf-page-wrapper" id="p4">
      <div className="page-wrapper page2-wrapper">
        <div className="background-div" />
        <PageHeader isLightPage={true} />
        <div className="preview-content">
          <div className="title no-padding">
            <h2>Set Up Connectivity</h2>
          </div>
          <div className="content">
            <div className="app-dash-img-1">
              <div className="texts-wrapper">
                <div className="text-block-1">
                  Open the Mesh++
                  App for setup
                </div>
                <div className="text-block-2">
                  Tap <span className="bt-icon">Search </span>
                  in the Mesh++ App
                </div>
                <div className="text-block-3">
                  Tap the node
                  in the app, and
                  turn it on via its<br />
                  <span style={{ color: "#1F9ACF", fontWeight: "bold" }}> ON/OFF toggle </span>
                  on the right side
                </div>
                <div className="text-block-4">
                  Configure as
                  needed and
                  connect to WiFi
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="app-dash-2">
              <div className="app-dash-img-2" />
              <div className="app-dash-2-text">
                <div className="title no-padding">
                  <h2>Congratulations!</h2>
                </div>
                <p className="blue-content">
                  Your S618 Mesh Node is now
                  set up and ready to help you
                  take the internet anywhere.
                </p>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="app-dash-3">
              <div className="title-block">
                <h2>Installing S618 Mesh Node!</h2>
              </div>
              <div className="subtitle-block">
                Self-Configuring Outdoor Wifi Radio<br />
                <span>Instruction Manual</span>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="app-dash-4">
              <span>Disclaimer</span>
              <p>MESHPLUSPLUS INC. MAKES NO WARRANTY OF ANY KIND WITH REGARD TO THIS MATERIAL, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. MeshPlusPlus, Inc. shall not be liable for errors contained herein or for incidental or consequential damages
              in connection with the furnishing, performance, or use of this material. Nothing herein should be construed as constituting an additional warranty. MeshPlusPlus, Inc. shall
              not be liable for technical or editorial errors or omissions contained herein. MeshPlusPlus, Inc. assumes no responsibility for the use or reliability of its equipment. THIS
              PRODUCT HAS NOT BEEN CERTIFIED BY THE FEDERAL COMMUNICATIONS COMMISSION, ALTHOUGH INDEPENDENTLY VERIFIED TO BE COMPLIANT AS A CLASS B
              ELECTRONIC DEVICE. 5.15-5.25GHZ OPERATION IS LIMITED TO INDOOR APPLICATIONS ONLY. THIS PRODUCT MAY NOT BE USED FOR UNLAWFUL PURPOSES AND
              THAT USE IS EXPRESSELY PROHIBITED UNDER THE TERMS AND CONDITIONS OF ITS USE.
              © Copyright 2021 MeshPlusPlus, Inc. The information contained herein is subject to change without notice.
              This document contains proprietary information, which is protected by copyright. No part of this document may be photocopied, reproduced, or translated into another
                language without the prior written consent of MeshPlusPlus, Inc.</p>
            </div>
          </div>
          {/*<div className="title second-title blue-title">
            <h2>Inner title</h2>
          </div>*/}
          {/*<div className="page-divider" />*/}
        </div>
        <PageFooter current={current} total={total} />
      </div>
    </div>
  );
}
